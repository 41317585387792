/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type OvertimeType,
} from "models/overtime/types";

const OVERTIME_TYPE_DEFAULT: OvertimeType = "DEFAULT";

enum OvertimeStatus {
	APPROVED = "APPROVED",
	CANCELLED = "CANCELED",
	REJECTED = "REJECTED",
	SUBMITTED = "SUBMITTED",
}

const OVERTIME_STATUS_NAME: Record<OvertimeStatus, string> = {
	[OvertimeStatus.APPROVED]: "Approved",
	[OvertimeStatus.CANCELLED]: "Cancelled",
	[OvertimeStatus.REJECTED]: "Rejected",
	[OvertimeStatus.SUBMITTED]: "Submitted",
};

enum OvertimeRequestAttachmentStatus {
	METADEFENDER = "METADEFENDER",
	SANITIZED = "SANITIZED",
	LOCAL = "LOCAL",
	BLOCKED = "BLOCKED",
}

enum ProhibitedOvertimeTypeValidationRuleType {
	DAY_TYPE_OVERTIME_TYPE_AVAILABILITY = "DAY_TYPE_OVERTIME_TYPE_AVAILABILITY",
	PACKAGE_OVERTIME_TYPE_AVAILABILITY = "PACKAGE_OVERTIME_TYPE_AVAILABILITY",
	PROHIBIT_REPORTING = "PROHIBIT_REPORTING",
}

/*
	Prohibited overtime type validation rules sorted by processing priority.
	The priority is defined here: https://jira.epam.com/jira/browse/EPMTIME-13853
*/
const PROHIBITED_OVERTIME_TYPE_VALIDATION_RULE_TYPES: ProhibitedOvertimeTypeValidationRuleType[] = [
	ProhibitedOvertimeTypeValidationRuleType.PROHIBIT_REPORTING,
	ProhibitedOvertimeTypeValidationRuleType.PACKAGE_OVERTIME_TYPE_AVAILABILITY,
	ProhibitedOvertimeTypeValidationRuleType.DAY_TYPE_OVERTIME_TYPE_AVAILABILITY,
];

const PROHIBITED_OVERTIME_TYPE_VALIDATION_RULE_MESSAGE_MAP: Record<
	ProhibitedOvertimeTypeValidationRuleType,
	string
> = {
	[ProhibitedOvertimeTypeValidationRuleType.DAY_TYPE_OVERTIME_TYPE_AVAILABILITY]: (
		"Overtime reporting is unavailable for the selected date"
	),
	[ProhibitedOvertimeTypeValidationRuleType.PACKAGE_OVERTIME_TYPE_AVAILABILITY]: (
		"Overtime reporting is unavailable for the selected reporting type"
	),
	[ProhibitedOvertimeTypeValidationRuleType.PROHIBIT_REPORTING]: (
		"Overtime reporting is unavailable for the period of leave"
	),
};

export {
	OVERTIME_TYPE_DEFAULT,
	OvertimeStatus,
	OVERTIME_STATUS_NAME,
	OvertimeRequestAttachmentStatus,
	ProhibitedOvertimeTypeValidationRuleType,
	PROHIBITED_OVERTIME_TYPE_VALIDATION_RULE_TYPES,
	PROHIBITED_OVERTIME_TYPE_VALIDATION_RULE_MESSAGE_MAP,
};
