/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	overtimeApi,
} from "models/overtime/api";
import {
	type OvertimeRequestForUpdate,
} from "models/overtime/endpoints";
import {
	type Dispatch,
} from "store";
import {
	type ApiError,
} from "types/api";

interface ErrorResponse {
	message: string | undefined;
}

interface UpdateOvertimeRequestParams {
	isNewPermissionSchemeEnabled: boolean;
	overtimeRequestToUpdate: OvertimeRequestForUpdate;
	dispatch: Dispatch;
}

const updateOvertimeRequest = async ({
	isNewPermissionSchemeEnabled,
	overtimeRequestToUpdate,
	dispatch,
}: UpdateOvertimeRequestParams): Promise<void> => {
	try {
		const updateOvertimeRequestForPermissionScheme = (
			isNewPermissionSchemeEnabled
				? overtimeApi.endpoints.updateOvertimeRequestV3.initiate
				: overtimeApi.endpoints.updateOvertimeRequest.initiate
		);

		await dispatch(
			updateOvertimeRequestForPermissionScheme(overtimeRequestToUpdate),
		)
			.unwrap();
	} catch (error) {
		const typedError = error as ApiError<ErrorResponse>;

		let errorMessage = "Something wrong";

		const errorMessageFromServer = typedError.data.response?.data.message;

		if (!isUndefined(errorMessageFromServer)) {
			errorMessage = errorMessageFromServer;
		}

		await Promise.reject(new Error(errorMessage));
	}
};

export {
	updateOvertimeRequest,
};
