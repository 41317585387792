/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";
import toNumber from "lodash/toNumber";

import {
	type OvertimeRequestId,
} from "models/overtime/types";
import {
	type ApiError,
} from "types/api";

interface ErrorResponse {
	// Stringified `overtimeId` value.
	entityId: string | undefined;
	message: string | undefined;
}

interface GetOvertimeRequestStatusChangeErrorParams {
	error: unknown;
	getErrorMessageForOvertimeRequestId: (
		overtimeRequestId: OvertimeRequestId,
	) => string | undefined;
}

const getOvertimeRequestStatusChangeErrorMessage = ({
	error,
	getErrorMessageForOvertimeRequestId,
}: GetOvertimeRequestStatusChangeErrorParams): string => {
	const typedError = error as ApiError<ErrorResponse>;

	let errorMessage = "Something wrong";

	const errorMessageFromServer = typedError.data.response?.data.message;

	if (!isUndefined(errorMessageFromServer)) {
		errorMessage = errorMessageFromServer;

		const overtimeRequestIdString = typedError.data.response?.data.entityId;

		if (!isUndefined(overtimeRequestIdString)) {
			const overtimeRequestId = toNumber(overtimeRequestIdString);

			const errorMessageForOvertimeRequestId = getErrorMessageForOvertimeRequestId(
				overtimeRequestId,
			);

			if (!isUndefined(errorMessageForOvertimeRequestId)) {
				errorMessage = `${errorMessageForOvertimeRequestId}  ${errorMessage}`;
			}
		}
	}

	return errorMessage;
};

export {
	getOvertimeRequestStatusChangeErrorMessage,
};
