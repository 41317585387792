/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";

import {
	type EmployeesCalendarInfo,
} from "./types";

interface GetEmployeeCalendarInfoParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

const getEmployeesCalendarInfo = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetEmployeeCalendarInfoParams): Promise<EmployeesCalendarInfo> => {
	const {
		data: employeesCalendarInfo,
	} = await api.post<EmployeesCalendarInfo>(
		"calendar/days",
		{
			employeeUids: employeeIds,
			startDate: periodStartDate,
			endDate: periodEndDate,
		},
	);

	return employeesCalendarInfo;
};

export {
	getEmployeesCalendarInfo,

	type GetEmployeeCalendarInfoParams,
};
