/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetEmployeesParams,
	type GetFilterValuesParams,
	type GetProjectEmployeesCountParams,
	type GetProjectEmployeesParams,
	type GetProjectEmployeesShortParams,
	type GetTargetEmployeesParams,
	type GetUserUnitEmployeesParams,
	getEmployeeShort,
	getEmployees,
	getEngagementPackages,
	getLocations,
	getPresenceAvailabilityOptions,
	getProjectEmployees,
	getProjectEmployeesCount,
	getProjectEmployeesShort,
	getResourceManagers,
	getSubordinates,
	getTargetEmployees,
	getUserUnitEmployees,
} from "./endpoints";
import {
	type EmployeeId,
	type EmployeeShort,
	type EmployeesReduced,
	type EmployeesShort,
	type PaginatedEmployees,
	type PaginatedEngagementPackages,
	type PaginatedLocations,
	type PaginatedPresenceAvailabilityOptions,
	type PaginatedResourceManagers,
	type PaginatedSubordinates,
	type ProjectEmployeesCount,
} from "./types";

const employeesApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProjectEmployees: build.query<
				EmployeesReduced,
				GetProjectEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const projectEmployees = await getProjectEmployees(params);

						return {
							data: projectEmployees,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectEmployeesCount: build.query<
				ProjectEmployeesCount,
				GetProjectEmployeesCountParams
			>({
				queryFn: async (params) => {
					try {
						const projectEmployeesCount = await getProjectEmployeesCount(params);

						return {
							data: projectEmployeesCount,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getEmployeeShort: build.query<
				EmployeeShort,
				EmployeeId
			>({
				queryFn: async (params) => {
					try {
						const employeeShort = await getEmployeeShort(params);

						return {
							data: employeeShort,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectEmployeesShort: build.query<
				EmployeesShort,
				GetProjectEmployeesShortParams
			>({
				queryFn: async (params) => {
					try {
						const projectEmployeesShort = await getProjectEmployeesShort(params);

						return {
							data: projectEmployeesShort,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getUserUnitEmployees: build.query<
				EmployeesShort,
				GetUserUnitEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const userUnitEmployees = await getUserUnitEmployees(params);

						return {
							data: userUnitEmployees,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getTargetEmployees: build.query<
				EmployeesShort,
				GetTargetEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const foundEmployees = await getTargetEmployees(params);

						return {
							data: foundEmployees,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getEmployees: build.query<
				PaginatedEmployees,
				GetEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const paginatedEmployees = await getEmployees(params);

						return {
							data: paginatedEmployees,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getSubordinates: build.query<
				PaginatedSubordinates,
				GetFilterValuesParams
			>({
				queryFn: async (params) => {
					try {
						const paginatedSubordinates = await getSubordinates(params);

						return {
							data: paginatedSubordinates,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getLocations: build.query<
				PaginatedLocations,
				GetFilterValuesParams
			>({
				queryFn: async (params) => {
					try {
						const paginatedLocations = await getLocations(params);

						return {
							data: paginatedLocations,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getEngagementPackages: build.query<
				PaginatedEngagementPackages,
				GetFilterValuesParams
			>({
				queryFn: async (params) => {
					try {
						const paginatedEngagementPackages = await getEngagementPackages(params);

						return {
							data: paginatedEngagementPackages,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getPresenceAvailabilityOptions: build.query<
				PaginatedPresenceAvailabilityOptions,
				GetFilterValuesParams
			>({
				queryFn: async (params) => {
					try {
						const paginatedPresenceAvailabilityOptions = await getPresenceAvailabilityOptions(
							params,
						);

						return {
							data: paginatedPresenceAvailabilityOptions,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getResourceManagers: build.query<
				PaginatedResourceManagers,
				GetFilterValuesParams
			>({
				queryFn: async (params) => {
					try {
						const paginatedResourceManagers = await getResourceManagers(
							params,
						);

						return {
							data: paginatedResourceManagers,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	employeesApi,
};
