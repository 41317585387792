/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetEmployeeCalendarInfoParams,
	getEmployeesCalendarInfo,
} from "./endpoints";
import {
	type EmployeesCalendarInfo,
} from "./types";

const employeesCalendarInfoApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getEmployeesCalendarInfo: build.query<
				EmployeesCalendarInfo,
				GetEmployeeCalendarInfoParams
			>({
				queryFn: async (params) => {
					try {
						const employeesCalendarInfo = await getEmployeesCalendarInfo(params);

						return {
							data: employeesCalendarInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	employeesCalendarInfoApi,
};
