/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import replace from "lodash/replace";

import {
	round,
	thousandSeparator,
} from "utilities/number";

import {
	type Duration,
} from "../types";

interface FormatDurationSettings {
	shouldFormatSign?: boolean;
}

const formatDuration = (
	duration: Duration,
	settings: FormatDurationSettings = {},
): string => {
	const {
		shouldFormatSign = false,
	} = settings;

	const formattedDuration = thousandSeparator(round(duration));

	if (shouldFormatSign) {
		if (duration < 0) {
			return replace(formattedDuration, "-", "−");
		}

		if (duration > 0) {
			return `+${formattedDuration}`;
		}
	}

	return formattedDuration;
};

export {
	formatDuration,
};
