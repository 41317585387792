/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	areIntervalsOverlapping,
	isAfter,
	isBefore,
	parseISO,
} from "date-fns";

import {
	type DateString,
} from "models/dates-and-time/types";

interface GetPresenceSettingPeriodResult {
	periodStartDate: DateString;
	periodEndDate: DateString;
}

interface GetPresenceSettingPeriodParams {
	presenceSettingStartDate: DateString;
	presenceSettingEndDate: DateString;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getPresenceSettingPeriod = ({
	presenceSettingStartDate: presenceSettingStartDateString,
	presenceSettingEndDate: presenceSettingEndDateString,
	periodStartDate: periodStartDateString,
	periodEndDate: periodEndDateString,
}: GetPresenceSettingPeriodParams): GetPresenceSettingPeriodResult | null => {
	const presenceSettingStartDate = parseISO(presenceSettingStartDateString);
	const presenceSettingEndDate = parseISO(presenceSettingEndDateString);
	const periodStartDate = parseISO(periodStartDateString);
	const periodEndDate = parseISO(periodEndDateString);

	const arePeriodsOverlapping = areIntervalsOverlapping(
		{
			start: presenceSettingStartDate,
			end: presenceSettingEndDate,
		},
		{
			start: periodStartDate,
			end: periodEndDate,
		},
		{
			inclusive: true,
		},
	);

	if (!arePeriodsOverlapping) {
		return null;
	}

	return {
		periodStartDate: isBefore(
			presenceSettingStartDate,
			periodStartDate,
		)
			? periodStartDateString
			: presenceSettingStartDateString,
		periodEndDate: isAfter(
			presenceSettingEndDate,
			periodEndDate,
		)
			? periodEndDateString
			: presenceSettingEndDateString,
	};
};

export {
	getPresenceSettingPeriod,
};
