/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type AxiosResponse,
} from "axios";

import {
	api,
} from "constants/api";

type UploadNpaHoursWarningMessage = string;

type UploadNpaHoursWarningMessages = UploadNpaHoursWarningMessage[];

type UploadNpaHoursResponse = AxiosResponse<UploadNpaHoursWarningMessages | undefined>;

const uploadNpaHoursV3 = async (file: File): Promise<UploadNpaHoursResponse> => {
	const data = new FormData();

	data.append("file", file);

	return await api.post(
		"v3/sync/npa/excel",
		data,
	);
};

export {
	uploadNpaHoursV3,

	type UploadNpaHoursResponse,
};
