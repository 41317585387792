/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	type ProjectId,
} from "models/projects/types";
import {
	getApiError,
} from "utils/api";

import {
	type GetProjectStructureChildrenParams,
	type GetProjectStructureSearchResultsParams,
	addProjectStructureUnitToFavorites,
	getFavoritesProjectStructure,
	getProjectStructureChildUnits,
	getProjectStructureParentIds,
	getProjectStructureRoot,
	getProjectStructureSearchResults,
	removeProjectStructureUnitFromFavorites,
} from "./endpoints";
import {
	type PaginatedProjectStructureUnits,
	type ProjectStructureUnit,
	type ProjectStructureUnitId,
	type ProjectStructureUnitIds,
	type ProjectStructureUnits,
} from "./types";

const projectStructureApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProjectStructureRoot: build.query<
				ProjectStructureUnit,
				undefined
			>({
				queryFn: async () => {
					try {
						const projectStructureRoot = await getProjectStructureRoot();

						return {
							data: projectStructureRoot,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getProjectStructureChildUnits: build.query<
				ProjectStructureUnits,
				GetProjectStructureChildrenParams
			>({
				queryFn: async (params) => {
					try {
						const projectStructureChildUnits = await getProjectStructureChildUnits(params);

						return {
							data: projectStructureChildUnits,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getProjectStructureParentIds: build.query<
				ProjectStructureUnitIds,
				ProjectId
			>({
				queryFn: async (params) => {
					try {
						const projectStructureParentIds = await getProjectStructureParentIds(params);

						return {
							data: projectStructureParentIds,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getProjectStructureSearchResults: build.query<
				PaginatedProjectStructureUnits,
				GetProjectStructureSearchResultsParams
			>({
				queryFn: async (params) => {
					try {
						const projectStructureSearchResults = await getProjectStructureSearchResults(params);

						return {
							data: projectStructureSearchResults,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getFavoritesProjectStructure: build.query<
				ProjectStructureUnits,
				undefined
			>({
				queryFn: async () => {
					try {
						const favoritesProjectStructure = await getFavoritesProjectStructure();

						return {
							data: favoritesProjectStructure,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				/*
					Since favorites list can be updated frequently, we don't need the data
					to be stored when all the subscribers are removed.
				*/
				keepUnusedDataFor: 0,
			}),
			addProjectStructureUnitToFavorites: build.mutation<
				undefined,
				ProjectStructureUnitId
			>({
				queryFn: async (params) => {
					try {
						await addProjectStructureUnitToFavorites(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			removeProjectStructureUnitFromFavorites: build.mutation<
				undefined,
				ProjectStructureUnitId
			>({
				queryFn: async (params) => {
					try {
						await removeProjectStructureUnitFromFavorites(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	projectStructureApi,
};
