/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type ProjectId,
} from "models/projects/types";
import {
	type FilterRequestBody,
} from "types/filter";
import {
	type PageIndex,
	type PageSize,
} from "types/pagination";

import {
	type PaginatedProjectPositions,
	type ProjectPositions,
} from "./types";

interface GetProjectPositionsParams {
	projectId: ProjectId;
	filterRequestBody: FilterRequestBody;
	pageIndex: PageIndex;
	pageSize: PageSize;
}

const getProjectPositions = async ({
	projectId,
	filterRequestBody,
	pageIndex,
	pageSize,
}: GetProjectPositionsParams): Promise<PaginatedProjectPositions> => {
	const {
		data: {
			content: positions,
			totalElements,
		},
	} = await api.post<PaginatedProjectPositions>(
		`project/${projectId}/positions`,
		{
			...filterRequestBody,
		},
		{
			params: {
				page: pageIndex,
				size: pageSize,
				sort: "employee.fullName,asc",
			},
		},
	);

	return {
		content: positions,
		totalElements,
	};
};

interface GetEmployeesProjectPositionsParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

const getEmployeesProjectPositions = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetEmployeesProjectPositionsParams): Promise<ProjectPositions> => {
	const {
		data: employeeProjectPositions,
	} = await api.get<ProjectPositions>(
		"employee/positions",
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return employeeProjectPositions;
};

export {
	getProjectPositions,
	getEmployeesProjectPositions,

	type GetProjectPositionsParams,
	type GetEmployeesProjectPositionsParams,
};
