/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";

import {
	type NonProjectWorklogs,
} from "./types";

interface GetNonProjectWorklogsParams {
	employeeIds: EmployeeIds;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getNonProjectWorklogs = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetNonProjectWorklogsParams): Promise<NonProjectWorklogs> => {
	const {
		data: nonProjectWorklogs,
	} = await api.get<NonProjectWorklogs>(
		"v2/worklogs/npa",
		{
			params: {
				employeeUids: employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return nonProjectWorklogs;
};

export {
	getNonProjectWorklogs,

	type GetNonProjectWorklogsParams,
};
