/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
enum ComparisonType {
	WORKLOAD = "workload",
	CALENDAR_NORM = "calendarNorm",
}

enum ComparisonSettingsType {
	EMPLOYEE = "EMPLOYEE",
	POSITION = "POSITION",
}

enum ActivityType {
	LEAVE = "LEAVE",
	NON_PROJECT = "NON_PROJECT",
	PROJECT = "PROJECT",
}

enum WorklogType {
	ON_DUTY = "ON_DUTY",
	OVERTIME = "OVERTIME",
	WORK = "WORK",
}

enum WorklogStatus {
	// Overtime statuses.
	APPROVED = "APPROVED",
	SUBMITTED = "SUBMITTED",

	// Leave statuses.
	REQUESTED = "REQUESTED",
	TAKEN = "TAKEN",
}

enum BillableType {
	BILLABLE = "BILLABLE",
	NON_BILLABLE = "NON_BILLABLE",
}

const COMPARISON_LEVEL_NAME: Readonly<Record<ComparisonType, string>> = {
	[ComparisonType.WORKLOAD]: "Position norm",
	[ComparisonType.CALENDAR_NORM]: "Calendar norm",
};

export {
	ComparisonSettingsType,
	ComparisonType,
	ActivityType,
	BillableType,
	WorklogStatus,
	WorklogType,
	COMPARISON_LEVEL_NAME,
};
