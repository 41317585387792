/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type Interval,
	eachDayOfInterval,
	isAfter,
	isValid,
	parseISO,
} from "date-fns";

import {
	type DateString,
	type PeriodDates,
} from "../types";

const PERIOD_DATES_FALLBACK: PeriodDates = [];

interface GeneratePeriodDatesParams {
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const generatePeriodDates = ({
	periodStartDate,
	periodEndDate,
}: GeneratePeriodDatesParams): PeriodDates => {
	const startDate = parseISO(periodStartDate);

	if (!isValid(startDate)) {
		return PERIOD_DATES_FALLBACK;
	}

	const endDate = parseISO(periodEndDate);

	if (!isValid(endDate)) {
		return PERIOD_DATES_FALLBACK;
	}

	const interval: Interval = isAfter(
		startDate,
		endDate,
	)
		? {
			start: endDate,
			end: startDate,
		}
		: {
			start: startDate,
			end: endDate,
		};

	return eachDayOfInterval(interval);
};

export {
	generatePeriodDates,
};
