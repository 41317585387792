/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ActivityType,
	WorklogStatus,
} from "models/comparisons/constants";
import {
	type LeaveWorklogs,
} from "models/leave-worklogs/types";

import {
	type ProjectPositionWorklogs,
} from "../types";

interface ConvertLeaveWorklogsParams {
	worklogs: LeaveWorklogs;
}

const convertLeaveWorklogs = ({
	worklogs,
}: ConvertLeaveWorklogsParams): ProjectPositionWorklogs => {
	return worklogs.map((worklog) => {
		const {
			positionId,
			date,
			duration,
			isPlanned,
			projectId,
		} = worklog;

		return {
			positionId,
			projectId,
			date,
			duration,
			activityType: ActivityType.LEAVE,
			status: isPlanned
				? WorklogStatus.REQUESTED
				: WorklogStatus.TAKEN,
		};
	});
};

export {
	convertLeaveWorklogs,
};
