/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type ProjectId,
} from "models/projects/types";

import {
	type PaginatedProjectStructureUnits,
	type ProjectStructureUnit,
	type ProjectStructureUnitId,
	type ProjectStructureUnitIds,
	type ProjectStructureUnits,
} from "./types";

const BUSINESS_UNIT_STRUCTURE_URL = "business-unit-structure/";
const FAVORITES_BUSINESS_UNIT_URL = (
	"settings/favorite/business-unit/"
);

interface ProjectStructureUnitsResponse<Units = ProjectStructureUnits> {
	units: Units;
}

const getProjectStructureRoot = async (): Promise<ProjectStructureUnit> => {
	const url = `${BUSINESS_UNIT_STRUCTURE_URL}root`;

	const {
		data: projectStructureRoot,
	} = await api.get<ProjectStructureUnit>(url);

	return projectStructureRoot;
};

interface GetProjectStructureChildrenParams {
	parentId: ProjectStructureUnitId;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getProjectStructureChildUnits = async ({
	parentId,
	periodStartDate,
	periodEndDate,
}: GetProjectStructureChildrenParams): Promise<ProjectStructureUnits> => {
	const {
		data: {
			units: projectStructureChildren,
		},
	} = await api.get<ProjectStructureUnitsResponse>(
		`${BUSINESS_UNIT_STRUCTURE_URL}${parentId}/children`,
		{
			params: {
				from: periodStartDate,
				to: periodEndDate,
			},
		},
	);

	return projectStructureChildren;
};

interface GetProjectStructureParentIdsParams {
	projectId: ProjectId;
}

interface GetProjectStructureParentIdsResponse {
	unitIds: ProjectStructureUnitIds;
}

const getProjectStructureParentIds = async (
	projectId: ProjectId,
): Promise<ProjectStructureUnitIds> => {
	const {
		data: {
			unitIds: projectStructureParentIds,
		},
	} = await api.get<GetProjectStructureParentIdsResponse>(
		`${BUSINESS_UNIT_STRUCTURE_URL}${projectId}/tree`,
	);

	return projectStructureParentIds;
};

interface GetProjectStructureSearchResultsParams {
	searchString: string;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

type GetProjectStructureSearchResultsResponse = ProjectStructureUnitsResponse<
	PaginatedProjectStructureUnits
>;

const getProjectStructureSearchResults = async ({
	searchString,
	periodStartDate,
	periodEndDate,
}: GetProjectStructureSearchResultsParams): Promise<PaginatedProjectStructureUnits> => {
	const {
		data: {
			units: {
				content: units,
				totalElements,
			},
		},
	} = await api.get<GetProjectStructureSearchResultsResponse>(
		`${BUSINESS_UNIT_STRUCTURE_URL}search`,
		{
			params: {
				name: searchString,
				from: periodStartDate,
				to: periodEndDate,
				page: 0,
				size: 50,
			},
		},
	);

	return {
		content: units,
		totalElements,
	};
};

const getFavoritesProjectStructure = async (): Promise<ProjectStructureUnits> => {
	const {
		data: {
			units: favoritesProjectStructure,
		},
	} = await api.get<ProjectStructureUnitsResponse>(FAVORITES_BUSINESS_UNIT_URL);

	return favoritesProjectStructure;
};

const addProjectStructureUnitToFavorites = async (
	unitId: ProjectStructureUnitId,
): Promise<void> => {
	await api.post(`${FAVORITES_BUSINESS_UNIT_URL}${unitId}`);
};

const removeProjectStructureUnitFromFavorites = async (
	unitId: ProjectStructureUnitId,
): Promise<void> => {
	await api.delete(`${FAVORITES_BUSINESS_UNIT_URL}${unitId}`);
};

export {
	BUSINESS_UNIT_STRUCTURE_URL,
	getProjectStructureRoot,
	getProjectStructureChildUnits,
	getProjectStructureParentIds,
	getProjectStructureSearchResults,
	getFavoritesProjectStructure,
	addProjectStructureUnitToFavorites,
	removeProjectStructureUnitFromFavorites,

	type ProjectStructureUnitsResponse,
	type GetProjectStructureChildrenParams,
	type GetProjectStructureParentIdsParams,
	type GetProjectStructureSearchResultsParams,
	type GetProjectStructureSearchResultsResponse,
};
