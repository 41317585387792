/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReduxToolkitQueryTag,
	reduxToolkitQueryApi,
} from "constants/api";
import {
	type EmployeeId,
} from "models/employees/types";
import {
	getApiError,
} from "utils/api";

import {
	type UpdateOutlookSyncTypeParams,
	getOutlookSyncInfo,
	updateOutlookSyncType,
} from "./endpoints";
import {
	type OutlookSyncInfo,
} from "./types";

const outlookSyncApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getOutlookSyncInfo: build.query<
				OutlookSyncInfo,
				EmployeeId
			>({
				queryFn: async (params) => {
					try {
						const outlookSyncInfo = await getOutlookSyncInfo(params);

						return {
							data: outlookSyncInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.OUTLOOK_SYNC,
					},
				],
			}),
			updateOutlookSyncType: build.mutation<
				undefined,
				UpdateOutlookSyncTypeParams
			>({
				queryFn: async (params) => {
					try {
						await updateOutlookSyncType(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OUTLOOK_SYNC,
					},
				],
			}),
		};
	},
});

export {
	outlookSyncApi,
};
