/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type ProjectPositionIds,
} from "models/project-positions/types";
import {
	type ProjectId,
} from "models/projects/types";

import {
	type LeaveWorklogs,
} from "./types";

interface GetLeaveWorklogsParams {
	projectPositionIds: ProjectPositionIds;
	projectId: ProjectId;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getLeaveWorklogs = async ({
	projectPositionIds,
	projectId,
	periodStartDate,
	periodEndDate,
}: GetLeaveWorklogsParams): Promise<LeaveWorklogs> => {
	const {
		data: leaveWorklogs,
	} = await api.get<LeaveWorklogs>(
		`project/${projectId}/leave`,
		{
			params: {
				positionIds: projectPositionIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return leaveWorklogs;
};

export {
	getLeaveWorklogs,

	type GetLeaveWorklogsParams,
};
