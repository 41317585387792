/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type TagDescription,
} from "@reduxjs/toolkit/query";

import {
	ReduxToolkitQueryTag,
	reduxToolkitQueryApi,
} from "constants/api";
import {
	type ProjectIds,
} from "models/projects/types";
import {
	getApiError,
} from "utils/api";

import {
	type GetEmployeesProjectsLocksParams,
	type GetProjectEmployeesLockInfoParams,
	type GetProjectLockInfoParams,
	type GetProjectLockStatisticsParams,
	type LockBillingForProjectEmployeesParams,
	type LockBillingForProjectsParams,
	type LockReportingForProjectsParams,
	type LockReportingLockForProjectsIntelligentParams,
	type LockUnlockReportingForProjectEmployeesParams,
	type UnlockBillingForProjectEmployeesParams,
	type UnlockBillingForProjectEmployeesResponse,
	getEmployeesProjectsLockInfo,
	getProjectEmployeesLockInfo,
	getProjectLockInfo,
	getProjectLockStatistics,
	getRedLockInfo,
	getReportingLockStatusesInfoForProjectsIntelligent,
	getReportingLockStatusesInfoForProjectsIntelligentV3,
	lockBillingForProjectEmployees,
	lockBillingForProjects,
	lockReportingForProjects,
	lockReportingLockForProjectsIntelligent,
	lockReportingLockForProjectsIntelligentV3,
	lockUnlockReportingForProjectEmployees,
	unlockBillingForProjectEmployees,
} from "./endpoints";
import {
	type EmployeesProjectsPeriodLockInfo,
	type ProjectEmployeesPeriodLockInfo,
	type ProjectLockInfo,
	type ProjectLockStatistics,
	type RedLockInfo,
	type ReportingLockStatusesInfoForProjects,
} from "./types";

const LOCK_INFO_TAGS_TO_INVALIDATE: TagDescription<ReduxToolkitQueryTag>[] = [
	{
		type: ReduxToolkitQueryTag.LOCKS,
		id: "project-lock-info",
	},
	{
		type: ReduxToolkitQueryTag.LOCKS,
		id: "project-employees-lock-info",
	},
	{
		type: ReduxToolkitQueryTag.LOCKS,
		id: "project-lock-statistics",
	},
	{
		type: ReduxToolkitQueryTag.LOCKS,
		id: "red-lock-info",
	},
];

const locksApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProjectLockInfo: build.query<
				ProjectLockInfo,
				GetProjectLockInfoParams
			>({
				queryFn: async (params) => {
					try {
						const projectLockInfo = await getProjectLockInfo(params);

						return {
							data: projectLockInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.LOCKS,
						id: "project-lock-info",
					},
				],
			}),
			getProjectEmployeesLockInfo: build.query<
				ProjectEmployeesPeriodLockInfo,
				GetProjectEmployeesLockInfoParams
			>({
				queryFn: async (params) => {
					try {
						const projectEmployeesLockInfo = await getProjectEmployeesLockInfo(params);

						return {
							data: projectEmployeesLockInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.LOCKS,
						id: "project-employees-lock-info",
					},
				],
			}),
			getEmployeesProjectsLockInfo: build.query<
				EmployeesProjectsPeriodLockInfo,
				GetEmployeesProjectsLocksParams
			>({
				queryFn: async (params) => {
					try {
						const employeesProjectsLockInfo = await getEmployeesProjectsLockInfo(params);

						return {
							data: employeesProjectsLockInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.LOCKS,
						id: "project-employees-lock-info",
					},
				],
			}),
			getRedLockInfo: build.query<
				RedLockInfo,
				undefined
			>({
				queryFn: async () => {
					try {
						const redLockInfo = await getRedLockInfo();

						return {
							data: redLockInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.LOCKS,
						id: "red-lock-info",
					},
				],
			}),
			lockBillingForProjects: build.mutation<
				undefined,
				LockBillingForProjectsParams
			>({
				queryFn: async (params) => {
					try {
						await lockBillingForProjects(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: LOCK_INFO_TAGS_TO_INVALIDATE,
			}),
			lockReportingForProjects: build.mutation<
				undefined,
				LockReportingForProjectsParams
			>({
				queryFn: async (params) => {
					try {
						await lockReportingForProjects(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: LOCK_INFO_TAGS_TO_INVALIDATE,
			}),
			lockBillingForProjectEmployees: build.mutation<
				undefined,
				LockBillingForProjectEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						await lockBillingForProjectEmployees(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: LOCK_INFO_TAGS_TO_INVALIDATE,
			}),
			unlockBillingForProjectEmployees: build.mutation<
				UnlockBillingForProjectEmployeesResponse,
				UnlockBillingForProjectEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const response = await unlockBillingForProjectEmployees(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: LOCK_INFO_TAGS_TO_INVALIDATE,
			}),
			lockUnlockReportingForProjectEmployees: build.mutation<
				undefined,
				LockUnlockReportingForProjectEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						await lockUnlockReportingForProjectEmployees(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: LOCK_INFO_TAGS_TO_INVALIDATE,
			}),
			getProjectLockStatistics: build.query<
				ProjectLockStatistics,
				GetProjectLockStatisticsParams
			>({
				queryFn: async (params) => {
					try {
						const projectLockStatistics = await getProjectLockStatistics(params);

						return {
							data: projectLockStatistics,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.LOCKS,
						id: "project-lock-statistics",
					},
				],
			}),
			lockReportingLockForProjectsIntelligent: build.mutation<
				undefined,
				LockReportingLockForProjectsIntelligentParams
			>({
				queryFn: async (params) => {
					try {
						await lockReportingLockForProjectsIntelligent(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: LOCK_INFO_TAGS_TO_INVALIDATE,
			}),
			lockReportingLockForProjectsIntelligentV3: build.mutation<
				undefined,
				LockReportingLockForProjectsIntelligentParams
			>({
				queryFn: async (params) => {
					try {
						await lockReportingLockForProjectsIntelligentV3(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: LOCK_INFO_TAGS_TO_INVALIDATE,
			}),
			getReportingLockStatusesInfoForProjectsIntelligent: build.query<
				ReportingLockStatusesInfoForProjects,
				ProjectIds
			>({
				queryFn: async (params) => {
					try {
						const reportingLockStatusesInfoForProjects = await getReportingLockStatusesInfoForProjectsIntelligent(
							params,
						);

						return {
							data: reportingLockStatusesInfoForProjects,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getReportingLockStatusesInfoForProjectsIntelligentV3: build.query<
				ReportingLockStatusesInfoForProjects,
				ProjectIds
			>({
				queryFn: async (params) => {
					try {
						const reportingLockStatusesInfoForProjects = await getReportingLockStatusesInfoForProjectsIntelligentV3(
							params,
						);

						return {
							data: reportingLockStatusesInfoForProjects,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
		};
	},
});

export {
	locksApi,
	LOCK_INFO_TAGS_TO_INVALIDATE,
};
