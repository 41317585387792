/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type ProjectId,
	type ProjectIds,
} from "models/projects/types";
import {
	type ArrayResponse,
} from "types/api";

import {
	type EmployeesProjectsPeriodLockInfo,
	type ProjectEmployeesPeriodLockInfo,
	type ProjectLockInfo,
	type ProjectLockStatistics,
	type RedLockInfo,
	type ReportingLockStatusesInfoForProjects,
} from "./types";

interface GetProjectLockInfoParams extends WithPeriodDates {
	projectId: ProjectId;
}

const getProjectLockInfo = async ({
	projectId,
	periodStartDate,
	periodEndDate,
}: GetProjectLockInfoParams): Promise<ProjectLockInfo> => {
	const {
		data: projectLockInfo,
	} = await api.get<ProjectLockInfo>(
		`lock/project/${projectId}`,
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return projectLockInfo;
};

interface GetProjectEmployeesLockInfoParams {
	projectId: ProjectId;
	employeeIds: EmployeeIds;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getProjectEmployeesLockInfo = async ({
	projectId,
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetProjectEmployeesLockInfoParams): Promise<ProjectEmployeesPeriodLockInfo> => {
	const {
		data: projectEmployeesLockInfo,
	} = await api.get<ProjectEmployeesPeriodLockInfo>(
		`lock/project/${projectId}/employee`,
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return projectEmployeesLockInfo;
};

interface GetEmployeesProjectsLocksParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

type EmployeesProjectsLockInfoResponse = ArrayResponse<
	EmployeesProjectsPeriodLockInfo
>;

const getEmployeesProjectsLockInfo = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetEmployeesProjectsLocksParams): Promise<
	EmployeesProjectsPeriodLockInfo
> => {
	const {
		data: {
			values: employeesProjectsLockInfo,
		},
	} = await api.get<EmployeesProjectsLockInfoResponse>(
		"lock",
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return employeesProjectsLockInfo;
};

const getRedLockInfo = async (): Promise<RedLockInfo> => {
	const {
		data: redLockInfo,
	} = await api.get<RedLockInfo>("lock/red-lock");

	return redLockInfo;
};

interface LockBillingForProjectsParams {
	projectIds: ProjectIds;
	lockDate: DateString;
}

const lockBillingForProjects = async ({
	projectIds,
	lockDate,
}: LockBillingForProjectsParams): Promise<void> => {
	await api.put(
		"lock/lock-billing-project",
		{
			projectIds,
			lockDate,
		},
	);
};

interface LockReportingForProjectsParams {
	projectIds: ProjectIds;
	lockDate: DateString;
}

const lockReportingForProjects = async ({
	projectIds,
	lockDate,
}: LockReportingForProjectsParams): Promise<void> => {
	await api.put(
		"lock/lock-reporting-project",
		{
			projectIds,
			lockDate,
		},
	);
};

interface LockBillingForProjectEmployeesParams {
	projectId: ProjectId;
	employeeIds: EmployeeIds;
	periodStartDate?: DateString;
	periodEndDate: DateString;
}

const lockBillingForProjectEmployees = async ({
	projectId,
	employeeIds,
	periodStartDate,
	periodEndDate,
}: LockBillingForProjectEmployeesParams): Promise<void> => {
	await api.post(
		"lock/lock-billing-employee",
		{
			projectUid: projectId,
			employeeUids: employeeIds,
			startViewDate: periodStartDate,
			tillDate: periodEndDate,
		},
	);
};

interface UnlockBillingForProjectEmployeesParams {
	projectId: ProjectId;
	/*
		`null` means billing should be unlocked for all the employees on the
		project.
	*/
	employeeIds: EmployeeIds | null;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

interface UnlockBillingForProjectEmployeesResponse {
	unlockedEmployees: number;
}

const unlockBillingForProjectEmployees = async ({
	projectId,
	employeeIds,
	periodStartDate,
	periodEndDate,
}: UnlockBillingForProjectEmployeesParams): Promise<
	UnlockBillingForProjectEmployeesResponse
> => {
	const {
		data: unlockBillingForProjectEmployeesResponse,
	} = await api.post<UnlockBillingForProjectEmployeesResponse>(
		"lock/unlock-billing-employee",
		{
			pmcProjectId: projectId,
			pmcEmployeeIds: employeeIds,
			from: periodStartDate,
			to: periodEndDate,
		},
	);

	return unlockBillingForProjectEmployeesResponse;
};

interface LockUnlockReportingForProjectEmployeesParams {
	projectId: ProjectId;
	/*
		`null` means reporting should be unlocked for all the employees on the
		project.
	*/
	employeeIds: EmployeeIds | null;
	periodStartDate: DateString;
	periodEndDate: DateString;
	isLocked: boolean;
}

const lockUnlockReportingForProjectEmployees = async ({
	projectId,
	employeeIds,
	periodStartDate,
	periodEndDate,
	isLocked,
}: LockUnlockReportingForProjectEmployeesParams): Promise<void> => {
	await api.post(
		"lock/lock-reporting-employee",
		{
			pmcProjectId: projectId,
			pmcEmployeeIds: employeeIds,
			from: periodStartDate,
			to: periodEndDate,
			locked: isLocked,
		},
	);
};

interface GetProjectLockStatisticsParams extends WithPeriodDates {
	projectId: ProjectId;
}

const getProjectLockStatistics = async ({
	projectId,
	periodStartDate,
	periodEndDate,
}: GetProjectLockStatisticsParams): Promise<ProjectLockStatistics> => {
	const {
		data: projectLockStatistics,
	} = await api.get<ProjectLockStatistics>(
		`lock/project/${projectId}/statistics`,
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return projectLockStatistics;
};

interface LockReportingLockForProjectsIntelligentParams extends WithPeriodDates {
	projectIds: ProjectIds;
}

const lockReportingLockForProjectsIntelligent = async ({
	periodStartDate,
	periodEndDate,
	projectIds,
}: LockReportingLockForProjectsIntelligentParams): Promise<void> => {
	await api.post(
		"lock/reporting/auto",
		{
			startDate: periodStartDate,
			endDate: periodEndDate,
			projectIds,
		},
	);
};

const lockReportingLockForProjectsIntelligentV3 = async ({
	periodStartDate,
	periodEndDate,
	projectIds,
}: LockReportingLockForProjectsIntelligentParams): Promise<void> => {
	await api.post(
		"v3/lock/reporting/auto",
		{
			startDate: periodStartDate,
			endDate: periodEndDate,
			projectIds,
		},
	);
};

type ReportingLockStatusesInfoForProjectsIntelligentResponse = ArrayResponse<ReportingLockStatusesInfoForProjects>;

const getReportingLockStatusesInfoForProjectsIntelligent = async (projectIds: ProjectIds): Promise<
	ReportingLockStatusesInfoForProjects
> => {
	const {
		data: {
			values: reportingLockStatusesInfoForProjects,
		},
	} = await api.get<ReportingLockStatusesInfoForProjectsIntelligentResponse>(
		"lock/reporting/auto/status",
		{
			params: {
				projectIds,
			},
		},
	);

	return reportingLockStatusesInfoForProjects;
};

const getReportingLockStatusesInfoForProjectsIntelligentV3 = async (projectIds: ProjectIds): Promise<
	ReportingLockStatusesInfoForProjects
> => {
	const {
		data: {
			values: reportingLockStatusesInfoForProjects,
		},
	} = await api.get<ReportingLockStatusesInfoForProjectsIntelligentResponse>(
		"v3/lock/reporting/auto/status",
		{
			params: {
				projectIds,
			},
		},
	);

	return reportingLockStatusesInfoForProjects;
};

export {
	getProjectLockInfo,
	getProjectEmployeesLockInfo,
	getEmployeesProjectsLockInfo,
	getRedLockInfo,
	lockBillingForProjects,
	lockReportingForProjects,
	lockBillingForProjectEmployees,
	unlockBillingForProjectEmployees,
	lockUnlockReportingForProjectEmployees,
	getProjectLockStatistics,
	lockReportingLockForProjectsIntelligent,
	lockReportingLockForProjectsIntelligentV3,
	getReportingLockStatusesInfoForProjectsIntelligentV3,
	getReportingLockStatusesInfoForProjectsIntelligent,

	type GetProjectLockInfoParams,
	type GetProjectEmployeesLockInfoParams,
	type GetEmployeesProjectsLocksParams,
	type EmployeesProjectsLockInfoResponse,
	type LockBillingForProjectsParams,
	type LockReportingForProjectsParams,
	type LockBillingForProjectEmployeesParams,
	type UnlockBillingForProjectEmployeesParams,
	type UnlockBillingForProjectEmployeesResponse,
	type LockUnlockReportingForProjectEmployeesParams,
	type GetProjectLockStatisticsParams,
	type LockReportingLockForProjectsIntelligentParams,
};
