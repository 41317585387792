/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type ProjectId,
} from "models/projects/types";
import {
	type FilterRequestBody,
} from "types/filter";

import {
	type ProjectStatistic,
} from "./types";

interface GetProjectStatisticParams {
	projectId: ProjectId;
	filterRequestBody: FilterRequestBody;
}

const getProjectStatistic = async ({
	projectId,
	filterRequestBody,
}: GetProjectStatisticParams): Promise<ProjectStatistic> => {
	const {
		data: projectStatistic,
	} = await api.post<ProjectStatistic>(
		`project/${projectId}/statistic`,
		{
			filter: filterRequestBody,
			requestedInfo: [
				"REPORTED",
				"WORKLOAD",
				"POSITION",
				"LEAVE",
				"NPA",
				"MARKUP",
				"REFERENCE_HOURS",
			],
		},
	);

	return projectStatistic;
};

export {
	getProjectStatistic,

	type GetProjectStatisticParams,
};
