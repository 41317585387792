/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PresenceAvailabilityBooleanOption,
	type PresenceAvailabilityBooleanOptions,
	type PresenceAvailabilityOption,
	type PresenceAvailabilityOptions,
} from "models/employees/types";
import {
	type FilterPresenceAvailabilityOptions,
} from "pages/employees-dashboard/types";

const getPresenceAvailabilityOptions = (
	presenceAvailabilityBooleanOptions: PresenceAvailabilityBooleanOptions,
): PresenceAvailabilityOptions => {
	return presenceAvailabilityBooleanOptions.map<PresenceAvailabilityOption>((booleanOption) => {
		return {
			id: (
				booleanOption
					? "1"
					: "0"
			),
			name: (
				booleanOption
					? "Enabled"
					: "Disabled"
			),
		};
	});
};

const getPresenceAvailabilityBooleanOptions = (
	filterPresenceAvailabilityOptions: FilterPresenceAvailabilityOptions,
): PresenceAvailabilityBooleanOptions => {
	return filterPresenceAvailabilityOptions.map<PresenceAvailabilityBooleanOption>((filterOption) => {
		return filterOption.id === "1";
	});
};

export {
	getPresenceAvailabilityOptions,
	getPresenceAvailabilityBooleanOptions,
};
