/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type ProjectId,
} from "models/projects/types";
import {
	type EmployeeTipTypes,
} from "models/tips/types";
import {
	type FilterValuesOptional,
} from "pages/employees-dashboard/types";
import {
	type EmployeesSelectorTabKey,
} from "pages/time-journal/components/employee-selector/constants";
import {
	type FilterValues,
} from "types/filter";
import {
	type PageIndex,
	type PageSize,
} from "types/pagination";
import {
	queryParamsSerializerWithRepeatedKeys,
} from "utils/api";
import {
	getRequestBodyFromFilterValues,
} from "utils/filter";
import {
	getIds,
} from "utils/get-ids";

import {
	type EmployeeId,
	type EmployeeShort,
	type EmployeesReduced,
	type EmployeesShort,
	type FilterValuesForServer,
	type PaginatedEmployees,
	type PaginatedEngagementPackages,
	type PaginatedLocations,
	type PaginatedPresenceAvailabilityBooleanOptions,
	type PaginatedPresenceAvailabilityOptions,
	type PaginatedResourceManagers,
	type PaginatedSubordinates,
	type ProjectEmployeesCount,
} from "./types";
import {
	getPresenceAvailabilityBooleanOptions,
	getPresenceAvailabilityOptions,
} from "./utils/presence-availability-options";

interface GetProjectEmployeesParams {
	projectId: ProjectId;
	filterValues: FilterValues;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getProjectEmployees = async ({
	projectId,
	filterValues,
	periodStartDate,
	periodEndDate,
}: GetProjectEmployeesParams): Promise<EmployeesReduced> => {
	const {
		data: projectEmployees,
	} = await api.post<EmployeesReduced>(
		`project/${projectId}/employees`,
		{
			...getRequestBodyFromFilterValues({
				filterValues,
				periodStartDate,
				periodEndDate,
			}),
		},
	);

	return projectEmployees;
};

interface GetProjectEmployeesCountParams extends WithPeriodDates {
	projectId: ProjectId;
}

interface GetProjectEmployeesCountResponse {
	count: ProjectEmployeesCount;
}

const getProjectEmployeesCount = async ({
	projectId,
	periodStartDate,
	periodEndDate,
}: GetProjectEmployeesCountParams): Promise<ProjectEmployeesCount> => {
	const {
		data: {
			count: projectEmployeesCount,
		},
	} = await api.get<GetProjectEmployeesCountResponse>(
		`project/${projectId}/employees/count`,
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return projectEmployeesCount;
};

interface GetEmployeeShortParams {
	employeeId: EmployeeId;
}

const getEmployeeShort = async (
	employeeId: EmployeeId,
): Promise<EmployeeShort> => {
	const {
		data: employeeShort,
	} = await api.get<EmployeeShort>(`employee/${employeeId}`);

	return employeeShort;
};

interface GetProjectEmployeesShortParams {
	projectId: ProjectId;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

interface GetProjectEmployeesShortResponse {
	employees: EmployeesShort;
}

const getProjectEmployeesShort = async ({
	projectId,
	periodStartDate,
	periodEndDate,
}: GetProjectEmployeesShortParams): Promise<EmployeesShort> => {
	const {
		data: {
			employees: projectEmployeesShort,
		},
	} = await api.get<GetProjectEmployeesShortResponse>(
		"employee",
		{
			params: {
				projectId,
				from: periodStartDate,
				to: periodEndDate,
			},
		},
	);

	return projectEmployeesShort;
};

interface GetUserUnitEmployeesParams {
	shouldIncludeDirectEmployeesOnly: boolean;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

interface GetUserUnitEmployeesResponse {
	employees: EmployeesShort;
}

const getUserUnitEmployees = async ({
	shouldIncludeDirectEmployeesOnly,
	periodStartDate,
	periodEndDate,
}: GetUserUnitEmployeesParams): Promise<EmployeesShort> => {
	const {
		data: {
			employees: userUnitEmployees,
		},
	} = await api.get<GetUserUnitEmployeesResponse>(
		"employee/subordinates",
		{
			params: {
				activeOnly: true,
				recursive: !shouldIncludeDirectEmployeesOnly,
				from: periodStartDate,
				to: periodEndDate,
			},
		},
	);

	return userUnitEmployees;
};

interface GetTargetEmployeesParams {
	searchString: string;
	target: EmployeesSelectorTabKey;
}

interface GetTargetEmployeesResponse {
	employees: EmployeesShort;
}

const getTargetEmployees = async ({
	searchString,
	target,
}: GetTargetEmployeesParams): Promise<EmployeesShort> => {
	const {
		data: {
			employees: foundEmployees,
		},
	} = await api.get<GetTargetEmployeesResponse>(
		"employee/search",
		{
			params: {
				query: searchString,
				target,
			},
		},
	);

	return foundEmployees;
};

interface GetEmployeesParams extends WithPeriodDates {
	pageIndex: PageIndex;
	pageSize: PageSize;
	filterValues: FilterValuesForServer;
	selectedEmployeeTipTypes: EmployeeTipTypes;
	search?: string;
}

const getEmployees = async ({
	pageIndex,
	pageSize,
	periodStartDate,
	periodEndDate,
	filterValues,
	selectedEmployeeTipTypes,
	search = "",
}: GetEmployeesParams): Promise<PaginatedEmployees> => {
	const {
		employeeIds,
		locationIds,
		engagementPackageIds,
		presenceAvailability,
		resourceManagerIds,
		shouldIncludeOnlyDirectSubordinates,
	} = filterValues;

	const {
		data: {
			content: employees,
			totalElements,
		},
	} = await api.get<PaginatedEmployees>(
		"employee/accessible",
		{
			params: {
				employeeIds,
				locationIds,
				engagementPackageIds,
				presenceAvailability,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
				callToActionTipTypes: selectedEmployeeTipTypes,
				startDate: periodStartDate,
				endDate: periodEndDate,
				search,
				page: pageIndex,
				size: pageSize,
				sort: [
					"firstName,asc",
					"lastName,asc",
				],
			},
			paramsSerializer: (queryParams) => {
				return queryParamsSerializerWithRepeatedKeys({
					queryParams,
					keysToRepeat: [
						"sort",
					],
				});
			},
		},
	);

	return {
		content: employees,
		totalElements,
	};
};

interface GetFilterValuesParams extends WithPeriodDates {
	search?: string;
	filterValues: FilterValuesOptional;
	selectedEmployeeTipTypes: EmployeeTipTypes;
}

const getSubordinates = async ({
	filterValues,
	selectedEmployeeTipTypes,
	search = "",
	periodStartDate,
	periodEndDate,
}: GetFilterValuesParams): Promise<PaginatedSubordinates> => {
	const {
		engagementPackages = [],
		locations = [],
		presenceAvailability = [],
		resourceManagers = [],
		shouldIncludeOnlyDirectSubordinates = false,
	} = filterValues;

	const engagementPackageIds = getIds(engagementPackages);
	const locationIds = getIds(locations);
	const presenceAvailabilityBooleanOptions = getPresenceAvailabilityBooleanOptions(
		presenceAvailability,
	);
	const resourceManagerIds = getIds(resourceManagers);

	const {
		content: subordinates,
		totalElements,
	} = await getEmployees({
		pageIndex: 0,
		pageSize: 50,
		search,
		periodStartDate,
		periodEndDate,
		filterValues: {
			employeeIds: [],
			locationIds,
			engagementPackageIds,
			presenceAvailability: presenceAvailabilityBooleanOptions,
			resourceManagerIds,
			shouldIncludeOnlyDirectSubordinates,
		},
		selectedEmployeeTipTypes,
	});

	return {
		content: subordinates,
		totalElements,
	};
};

const getLocations = async ({
	search = "",
	filterValues,
	selectedEmployeeTipTypes,
	periodStartDate,
	periodEndDate,
}: GetFilterValuesParams): Promise<PaginatedLocations> => {
	const {
		engagementPackages = [],
		employees = [],
		presenceAvailability = [],
		resourceManagers = [],
		shouldIncludeOnlyDirectSubordinates = false,
	} = filterValues;

	const employeeIds = getIds(employees);
	const engagementPackageIds = getIds(engagementPackages);
	const presenceAvailabilityBooleanOptions = getPresenceAvailabilityBooleanOptions(
		presenceAvailability,
	);
	const resourceManagerIds = getIds(resourceManagers);

	const {
		data: {
			content,
			totalElements,
		},
	} = await api.get<PaginatedLocations>(
		"employee/accessible/locations",
		{
			params: {
				employeeIds,
				engagementPackageIds,
				presenceAvailability: presenceAvailabilityBooleanOptions,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
				callToActionTipTypes: selectedEmployeeTipTypes,
				search,
				startDate: periodStartDate,
				endDate: periodEndDate,
				page: 0,
				size: 50,
				sort: "locationName,asc",
			},
		},
	);

	return {
		content,
		totalElements,
	};
};

const getEngagementPackages = async ({
	search = "",
	filterValues,
	selectedEmployeeTipTypes,
	periodStartDate,
	periodEndDate,
}: GetFilterValuesParams): Promise<PaginatedEngagementPackages> => {
	const {
		locations = [],
		employees = [],
		presenceAvailability = [],
		resourceManagers = [],
		shouldIncludeOnlyDirectSubordinates = false,
	} = filterValues;

	const locationIds = getIds(locations);
	const employeeIds = getIds(employees);
	const presenceAvailabilityBooleanOptions = getPresenceAvailabilityBooleanOptions(
		presenceAvailability,
	);
	const resourceManagerIds = getIds(resourceManagers);

	const {
		data: {
			content,
			totalElements,
		},
	} = await api.get<PaginatedEngagementPackages>(
		"employee/accessible/engagement-packages",
		{
			params: {
				employeeIds,
				locationIds,
				presenceAvailability: presenceAvailabilityBooleanOptions,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
				callToActionTipTypes: selectedEmployeeTipTypes,
				search,
				startDate: periodStartDate,
				endDate: periodEndDate,
				page: 0,
				size: 50,
				sort: "name,asc",
			},
		},
	);

	return {
		content,
		totalElements,
	};
};

const getPresenceAvailabilityOptionsEndpoint = async ({
	search = "",
	filterValues,
	selectedEmployeeTipTypes,
	periodStartDate,
	periodEndDate,
}: GetFilterValuesParams): Promise<PaginatedPresenceAvailabilityOptions> => {
	const {
		engagementPackages = [],
		locations = [],
		employees = [],
		resourceManagers = [],
		shouldIncludeOnlyDirectSubordinates = false,
	} = filterValues;

	const engagementPackageIds = getIds(engagementPackages);
	const locationIds = getIds(locations);
	const employeeIds = getIds(employees);
	const resourceManagerIds = getIds(resourceManagers);

	const {
		data: {
			content,
			totalElements,
		},
	} = await api.get<PaginatedPresenceAvailabilityBooleanOptions>(
		"employee/accessible/presence-availability",
		{
			params: {
				engagementPackageIds,
				employeeIds,
				locationIds,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
				callToActionTipTypes: selectedEmployeeTipTypes,
				search,
				startDate: periodStartDate,
				endDate: periodEndDate,
				page: 0,
				size: 50,
				// So that "true" comes before "false", and options names have order as on mockups.
				sort: "desc",
			},
		},
	);

	const presenceAvailabilityOptions = getPresenceAvailabilityOptions(content);

	return {
		content: presenceAvailabilityOptions,
		totalElements,
	};
};

const getResourceManagers = async ({
	periodStartDate,
	periodEndDate,
	search = "",
	filterValues,
	selectedEmployeeTipTypes,
}: GetFilterValuesParams): Promise<PaginatedResourceManagers> => {
	const {
		employees = [],
		locations = [],
		engagementPackages = [],
		presenceAvailability = [],
		shouldIncludeOnlyDirectSubordinates = false,
	} = filterValues;

	const employeeIds = getIds(employees);
	const locationIds = getIds(locations);
	const engagementPackageIds = getIds(engagementPackages);
	const presenceAvailabilityBooleanOptions = getPresenceAvailabilityBooleanOptions(
		presenceAvailability,
	);

	const {
		data: {
			content,
			totalElements,
		},
	} = await api.get<PaginatedResourceManagers>(
		"employee/accessible/resource-managers",
		{
			params: {
				employeeIds,
				locationIds,
				engagementPackageIds,
				presenceAvailability: presenceAvailabilityBooleanOptions,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
				callToActionTipTypes: selectedEmployeeTipTypes,
				search,
				startDate: periodStartDate,
				endDate: periodEndDate,
				page: 0,
				size: 50,
				sort: "fullName,asc",
			},
		},
	);

	return {
		content,
		totalElements,
	};
};

export {
	getProjectEmployees,
	getProjectEmployeesCount,
	getEmployeeShort,
	getProjectEmployeesShort,
	getUserUnitEmployees,
	getTargetEmployees,
	getEmployees,
	getSubordinates,
	getLocations,
	getEngagementPackages,
	getPresenceAvailabilityOptionsEndpoint as getPresenceAvailabilityOptions,
	getResourceManagers,

	type GetProjectEmployeesParams,
	type GetProjectEmployeesCountParams,
	type GetProjectEmployeesCountResponse,
	type GetEmployeeShortParams,
	type GetProjectEmployeesShortParams,
	type GetProjectEmployeesShortResponse,
	type GetUserUnitEmployeesParams,
	type GetUserUnitEmployeesResponse,
	type GetTargetEmployeesParams,
	type GetTargetEmployeesResponse,
	type GetEmployeesParams,
	type GetFilterValuesParams,
};
