/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type Month,
} from "date-fns";
import {
	monthsInYear,
} from "date-fns/constants";
import {
	enUS,
} from "date-fns/locale";

// `date-fns` date formats.
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const LOCAL_SLASH_FORMAT = "MM/dd/yyyy";
const DATE_FULL_MONTH_FORMAT = "dd MMMM, yyyy";
const LOCK_DATE_FORMAT = "d MMM yyyy";
const JOURNAL_HEADER_DATE_FORMAT = "MMM d";
const CHOOSE_DATE_FORMAT = "dd MMM yyyy";
const PICKER_INPUT_DATE_FORMAT = "MMM dd, yyyy";
const FANCY_DATE_FORMAT = "MMMM d, yyyy";
const MARKUP_STATUS_DATE_FORMAT = "dd MMM, yyyy";
const DEFAULT_TIME_DATE_FORMAT = "HH:mm:ss";
const DATE_ADV_WITH_TIME_FORMAT = "MMM d yyyy, HH:mm:ss";
const SHORT_WEEK_DAY_NAME_FORMAT = "iii";
const FULL_YEAR_FORMAT = "yyyy";
const FULL_YEAR_AND_FULL_MONTH_FORMAT = "MMMM yyyy";
// 01, 14, etc.
const DAY_WITH_LEADING_ZERO = "dd";
// Monday, Tuesday, etc.
const DAY_OF_WEEK_NAME_FULL = "EEEE";

// UUI library date formats.

// Jan 04, 2023; Oct 18, 2023; etc.
const UUI_PICKER_INPUT_FORMAT = "MMM DD, YYYY";
// 04-Jan-2023; 18-Oct-2023; etc.
const UUI_PICKER_INPUT_WITH_DASHES_FORMAT = "DD-MMM-YYYY";
// January 4, 2023; October 18, 2023; etc.
const UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT = "MMMM D, YYYY";

// Duration constants.
const MAX_DAY_IN_MONTH = 31;

const getMonthNames = (): string[] => {
	const months: string[] = [];

	for (let index = 0; index < monthsInYear; index++) {
		months.push(
			enUS.localize.month(
				index as Month,
			),
		);
	}

	return months;
};

const getMonthNamesShort = (): string[] => {
	const months: string[] = [];

	for (let index = 0; index < monthsInYear; index++) {
		months.push(
			enUS.localize.month(
				index as Month,
				{
					width: "abbreviated",
				},
			),
		);
	}

	return months;
};

const MONTH_NAMES = getMonthNames();

const MONTH_NAMES_SHORTS = getMonthNamesShort();

const PERIODS = {
	DAY: "day",
	WEEK: "week",
	TWO_WEEKS: "2 weeks",
	MONTH: "month",
};

const CUSTOM_PERIOD = "custom";

enum Operation {
	ADD_MONTH = "addMonths",
	SUB_MONTH = "subMonths",
}

export {
	DEFAULT_DATE_FORMAT,
	LOCAL_SLASH_FORMAT,
	DATE_FULL_MONTH_FORMAT,
	LOCK_DATE_FORMAT,
	JOURNAL_HEADER_DATE_FORMAT,
	CHOOSE_DATE_FORMAT,
	PICKER_INPUT_DATE_FORMAT,
	FANCY_DATE_FORMAT,
	MARKUP_STATUS_DATE_FORMAT,
	DEFAULT_TIME_DATE_FORMAT,
	DATE_ADV_WITH_TIME_FORMAT,
	SHORT_WEEK_DAY_NAME_FORMAT,
	FULL_YEAR_FORMAT,
	FULL_YEAR_AND_FULL_MONTH_FORMAT,
	MAX_DAY_IN_MONTH,
	MONTH_NAMES,
	MONTH_NAMES_SHORTS,
	PERIODS,
	CUSTOM_PERIOD,
	Operation,
	DAY_WITH_LEADING_ZERO,
	DAY_OF_WEEK_NAME_FULL,
	UUI_PICKER_INPUT_FORMAT,
	UUI_PICKER_INPUT_WITH_DASHES_FORMAT,
	UUI_FULL_MONTH_NAME_DAY_FULL_YEAR_FORMAT,
};
