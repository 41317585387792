/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";

import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type EmployeeTipType,
	type PROJECT_TIP,
} from "./constants";
import {
	type GetCallToActionTipTypesParams,
	type GetCallToActionTipsParams,
	getCallToActionTipTypes,
	getCallToActionTips,
} from "./endpoints";
import {
	type CallToActionTips,
	type EmployeeTipTypes,
	type ProjectTipTypes,
} from "./types";

const tipsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getEmployeesCallToActionTipTypes: build.query<
				EmployeeTipTypes,
				GetCallToActionTipTypesParams<EmployeeTipTypes>
			>({
				queryFn: async (params) => {
					try {
						const callToActionTipTypes = await getCallToActionTipTypes(params);

						return {
							data: callToActionTipTypes,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectCallToActionTipTypes: build.query<
				ProjectTipTypes,
				GetCallToActionTipTypesParams<ProjectTipTypes>
			>({
				queryFn: async (params) => {
					try {
						const callToActionTipTypes = await getCallToActionTipTypes(params);

						return {
							data: callToActionTipTypes,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getEmployeesCallToActionTips: build.query<
				CallToActionTips<EmployeeTipType>,
				GetCallToActionTipsParams<EmployeeTipType>
			>({
				queryFn: async (params) => {
					try {
						if (isEmpty(params.callToActionTipTypes)) {
							return {
								data: [],
							};
						}

						const callToActionTips = await getCallToActionTips(params);

						return {
							data: callToActionTips,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectCallToActionTips: build.query<
				CallToActionTips<PROJECT_TIP>,
				GetCallToActionTipsParams<PROJECT_TIP>
			>({
				queryFn: async (params) => {
					try {
						if (isEmpty(params.callToActionTipTypes)) {
							return {
								data: [],
							};
						}

						const callToActionTips = await getCallToActionTips(params);

						return {
							data: callToActionTips,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	tipsApi,
};
