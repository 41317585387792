/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";

import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	generatePeriodDates,
} from "models/dates-and-time/utils/generate-period-dates";
import {
	toDateString,
} from "models/dates-and-time/utils/to-date-string";

import {
	type PresenceSettings,
	type PresenceSettingsByDate,
} from "../types";
import {
	getPresenceSettingPeriod,
} from "./get-presence-setting-period";

interface GetPresenceSettingsByDateParams extends WithPeriodDates {
	presenceSettings: PresenceSettings;
}

const getPresenceSettingsByDate = ({
	presenceSettings,
	periodStartDate,
	periodEndDate,
}: GetPresenceSettingsByDateParams): PresenceSettingsByDate => {
	return presenceSettings.reduce<PresenceSettingsByDate>(
		(settingsByDates, presenceSetting) => {
			const {
				startDate,
				endDate,
				presenceType,
				availableActivities,
			} = presenceSetting;

			const presenceSettingPeriod = getPresenceSettingPeriod({
				presenceSettingStartDate: startDate,
				presenceSettingEndDate: endDate,
				periodStartDate,
				periodEndDate,
			});

			if (isNull(presenceSettingPeriod)) {
				return settingsByDates;
			}

			const periodDates = generatePeriodDates(presenceSettingPeriod);

			periodDates.forEach((date) => {
				const dateString = toDateString(date);

				// In this case, mutation is safe.
				// eslint-disable-next-line no-param-reassign
				settingsByDates[dateString] = {
					availableActivities,
					presenceType,
				};
			});

			return settingsByDates;
		},
		{},
	);
};

export {
	getPresenceSettingsByDate,
};
