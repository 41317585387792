/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
enum MarkupStatusName {
	CONFIRMED = "Confirmed",
	PRE_REJECTED = "Pre-Rejected",
	REJECTED = "Rejected",
}

enum MarkupAction {
	REMOVE = "REMOVE",
	APPLY = "APPLY",
}

enum MarkupAppliedLevel {
	PROJECT = "PROJECT",
	EMPLOYEE = "EMPLOYEE",
}

enum MarkupPeriod {
	PERIOD = "PERIOD",
	TILL_DATE = "TILL_DATE",
}

export {
	MarkupStatusName,
	MarkupAction,
	MarkupAppliedLevel,
	MarkupPeriod,
};
