/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	getUserInfo,
} from "./endpoints";
import {
	type UserInfo,
} from "./types";

const userInfoApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getUserInfo: build.query<
				UserInfo,
				undefined
			>({
				queryFn: async () => {
					try {
						const userInfo = await getUserInfo();

						return {
							data: userInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	userInfoApi,
};
