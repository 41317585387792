/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";

import {
	type UserInfo,
} from "./types";

const getUserInfo = async (): Promise<UserInfo> => {
	const {
		data: userInfo,
	} = await api.get<UserInfo>("info");

	return userInfo;
};

export {
	getUserInfo,
};
