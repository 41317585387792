/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";

import {
	api,
} from "constants/api";

import {
	type ProblemsNotificationInfo,
} from "./types";

const getProblemsNotificationInfo = async (): Promise<
	ProblemsNotificationInfo | undefined
> => {
	const {
		data: problemsNotificationInfo,
	} = await api.get<ProblemsNotificationInfo>("info/alerts");

	/*
		In case of no problems BE sends an empty response, which is casted to an
		empty string.
	*/
	if (isEmpty(problemsNotificationInfo)) {
		return undefined;
	}

	return problemsNotificationInfo;
};

export {
	getProblemsNotificationInfo,
};
