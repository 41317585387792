/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";

import {
	type EmployeeWorklogs,
} from "./types";

interface GetEmployeeWorklogsParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
	shouldIncludeLeaves: boolean;
}

const getEmployeeWorklogs = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
	shouldIncludeLeaves,
}: GetEmployeeWorklogsParams): Promise<EmployeeWorklogs> => {
	const {
		data: employeeWorklogs,
	} = await api.get<EmployeeWorklogs>(
		"v2/worklogs",
		{
			params: {
				employeeUids: employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
				withLeave: shouldIncludeLeaves,
			},
		},
	);

	return employeeWorklogs;
};

export {
	getEmployeeWorklogs,

	type GetEmployeeWorklogsParams,
};
