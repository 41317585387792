/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
	type EngagementPackageIds,
	type LocationIds,
	type PresenceAvailabilityBooleanOptions,
} from "models/employees/types";
import {
	type ProjectIds,
} from "models/projects/types";
import {
	type ArrayResponse,
} from "types/api";

import {
	type CallToActionTipLevels,
	type CallToActionTipType,
	type CallToActionTipTypes,
	type CallToActionTips,
} from "./types";

interface GetCallToActionTipTypesParams<
	Type extends CallToActionTipTypes,
> extends WithPeriodDates {
	callToActionTipLevels: CallToActionTipLevels;
	callToActionTipTypes: Type;
	appliedCallToActionTipTypes: Type;
	employeeIds: EmployeeIds;
	locationIds?: LocationIds;
	engagementPackageIds?: EngagementPackageIds;
	presenceAvailability?: PresenceAvailabilityBooleanOptions;
	resourceManagerIds?: EmployeeIds;
	shouldIncludeOnlyDirectSubordinates?: boolean;
	projectIds?: ProjectIds;
	withGaps?: boolean;
	withOverreporting?: boolean;
	search?: string;
}

type GetCallToActionTipTypesResponse<
	Type extends CallToActionTipTypes,
> = ArrayResponse<Type>;

const getCallToActionTipTypes = async <Types extends CallToActionTipTypes>({
	periodStartDate,
	periodEndDate,
	callToActionTipLevels,
	callToActionTipTypes,
	appliedCallToActionTipTypes,
	employeeIds,
	locationIds,
	engagementPackageIds,
	presenceAvailability,
	resourceManagerIds,
	shouldIncludeOnlyDirectSubordinates,
	projectIds,
	withGaps,
	withOverreporting,
	search,
}: GetCallToActionTipTypesParams<Types>): Promise<Types> => {
	const {
		data: {
			values: callToActionTipTypesResponse,
		},
	} = await api.post<GetCallToActionTipTypesResponse<Types>>(
		"call-to-action-tips/types",
		{
			employeeIds,
			locationIds,
			engagementPackageIds,
			presenceAvailability,
			resourceManagerIds,
			onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
			callToActionTipTypes,
			appliedCallToActionTipTypes,
			withGaps,
			withOverreporting,
			projectIds,
			callToActionSearchScopes: callToActionTipLevels,
			search,
			startDate: periodStartDate,
			endDate: periodEndDate,
		},
	);

	return callToActionTipTypesResponse;
};

interface GetCallToActionTipsParams<
	Type = CallToActionTipType,
> extends WithPeriodDates {
	callToActionTipLevels: CallToActionTipLevels;
	callToActionTipTypes: Type[];
	employeeIds: EmployeeIds;
	projectIds?: ProjectIds;
}

type GetCallToActionTipsResponse<
	Type extends CallToActionTipType,
> = ArrayResponse<CallToActionTips<Type>>;

const getCallToActionTips = async <Type extends CallToActionTipType>({
	employeeIds,
	projectIds,
	callToActionTipTypes,
	callToActionTipLevels,
	periodStartDate,
	periodEndDate,
}: GetCallToActionTipsParams<Type>): Promise<CallToActionTips<Type>> => {
	const {
		data: {
			values: callToActionTips,
		},
	} = await api.post<GetCallToActionTipsResponse<Type>>(
		"call-to-action-tips",
		{
			employeeIds,
			projectIds,
			callToActionTipTypes,
			callToActionSearchScopes: callToActionTipLevels,
			startDate: periodStartDate,
			endDate: periodEndDate,
		},
	);

	return callToActionTips;
};

export {
	getCallToActionTipTypes,
	getCallToActionTips,

	type GetCallToActionTipTypesParams,
	type GetCallToActionTipsParams,
};
