/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type AxiosRequestConfig,
	type AxiosResponse,
} from "axios";

import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type FilterValuesForServer,
} from "models/employees/types";
import {
	type EmployeeTipTypes,
} from "models/tips/types";

interface WithDetailsOption {
	withDetails: boolean;
}

interface WithOnlyGapOption {
	onlyGap: boolean;
}

interface WithOnlyOverReportingOption {
	onlyOverreport: boolean;
}

interface WithShouldDeferOption {
	defer: boolean;
}

interface WithCallToActionTipTypes {
	callToActionTipTypes: EmployeeTipTypes;
}

type ReportResponse = AxiosResponse<Blob>;

const REPORT_REQUEST_CONFIG: Pick<AxiosRequestConfig, "responseType" | "headers"> = {
	responseType: "blob",
	headers: {
		Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	},
};

interface GetEmployeeReportTotalParams extends
	WithPeriodDates,
	WithDetailsOption,
	WithOnlyGapOption,
	WithOnlyOverReportingOption,
	WithShouldDeferOption,
	WithCallToActionTipTypes,
	FilterValuesForServer {}

const getEmployeeReportTotal = async ({
	periodStartDate,
	periodEndDate,
	callToActionTipTypes,
	withDetails,
	onlyGap,
	onlyOverreport,
	defer,
	employeeIds,
	locationIds,
	engagementPackageIds,
	presenceAvailability,
	resourceManagerIds,
	shouldIncludeOnlyDirectSubordinates,
}: GetEmployeeReportTotalParams): Promise<ReportResponse> => {
	return await api.get<Blob>(
		"employees/reports/total",
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
				withDetails,
				onlyGap,
				onlyOverreport,
				defer,
				callToActionTipTypes,
				employeeIds,
				locationIds,
				engagementPackageIds,
				presenceAvailability,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
			},
			...REPORT_REQUEST_CONFIG,
		},
	);
};

interface GetEmployeeReportTotalForHrParams extends
	WithPeriodDates,
	WithDetailsOption,
	WithShouldDeferOption,
	WithCallToActionTipTypes,
	FilterValuesForServer {}

const getEmployeeReportTotalForHr = async ({
	periodStartDate,
	periodEndDate,
	withDetails,
	defer,
	callToActionTipTypes,
	employeeIds,
	locationIds,
	engagementPackageIds,
	presenceAvailability,
	resourceManagerIds,
	shouldIncludeOnlyDirectSubordinates,
}: GetEmployeeReportTotalForHrParams): Promise<ReportResponse> => {
	return await api.get<Blob>(
		"employees/reports/total-for-hr",
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
				withDetails,
				defer,
				callToActionTipTypes,
				employeeIds,
				locationIds,
				engagementPackageIds,
				presenceAvailability,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
			},
			...REPORT_REQUEST_CONFIG,
		},
	);
};

interface GetEmployeeReportSummaryParams extends
	WithPeriodDates,
	WithOnlyGapOption,
	WithOnlyOverReportingOption,
	WithShouldDeferOption,
	WithCallToActionTipTypes,
	FilterValuesForServer {}

const getEmployeeReportSummary = async ({
	periodStartDate,
	periodEndDate,
	onlyGap,
	onlyOverreport,
	defer,
	callToActionTipTypes,
	employeeIds,
	locationIds,
	engagementPackageIds,
	presenceAvailability,
	resourceManagerIds,
	shouldIncludeOnlyDirectSubordinates,
}: GetEmployeeReportSummaryParams): Promise<ReportResponse> => {
	return await api.get<Blob>(
		"employees/reports/summary",
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
				onlyGap,
				onlyOverreport,
				defer,
				callToActionTipTypes,
				employeeIds,
				locationIds,
				engagementPackageIds,
				presenceAvailability,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
			},
			...REPORT_REQUEST_CONFIG,
		},
	);
};

interface GetEmployeeReportPresenceMisreportingParams extends
	WithPeriodDates,
	WithCallToActionTipTypes,
	FilterValuesForServer {}

const getEmployeeReportPresenceMisreporting = async ({
	periodStartDate,
	periodEndDate,
	callToActionTipTypes,
	employeeIds,
	locationIds,
	engagementPackageIds,
	presenceAvailability,
	resourceManagerIds,
	shouldIncludeOnlyDirectSubordinates,
}: GetEmployeeReportPresenceMisreportingParams): Promise<ReportResponse> => {
	return await api.get<Blob>(
		"employees/reports/presence-misreporting",
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
				callToActionTipTypes,
				employeeIds,
				locationIds,
				engagementPackageIds,
				presenceAvailability,
				resourceManagerIds,
				onlyDirectSubordinates: shouldIncludeOnlyDirectSubordinates,
			},
			...REPORT_REQUEST_CONFIG,
		},
	);
};

export {
	getEmployeeReportTotal,
	getEmployeeReportTotalForHr,
	getEmployeeReportSummary,
	getEmployeeReportPresenceMisreporting,

	type ReportResponse,
	type GetEmployeeReportTotalParams,
	type GetEmployeeReportTotalForHrParams,
	type GetEmployeeReportSummaryParams,
	type GetEmployeeReportPresenceMisreportingParams,
};
