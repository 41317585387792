/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	OvertimeStatus,
} from "models/overtime/constants";
import {
	toCapitalCase,
} from "utils/formatting";

const getOvertimeStatusName = (overtimeStatus: OvertimeStatus): string => {
	// Fixing BE value misspelling.
	if (overtimeStatus === OvertimeStatus.CANCELLED) {
		return "Cancelled";
	}

	return toCapitalCase(overtimeStatus);
};

export {
	getOvertimeStatusName,
};
