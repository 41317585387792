/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReduxToolkitQueryTag,
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type ApproveOvertimeRequestsParams,
	type CancelOvertimeRequestParams,
	type GetAvailableOvertimeTypeOptionsParams,
	type GetAvailableOvertimeTypeOptionsResponse,
	type GetOvertimeAttachmentFileParams,
	type GetOvertimeAttachmentFileResult,
	type GetOvertimeExcelReportParams,
	type GetOvertimeExcelReportResult,
	type GetOvertimeRequestsParams,
	type OvertimeRequestForUpdate,
	type RejectOvertimeRequestsParams,
	type RemoveOvertimeAttachmentParams,
	type UploadOvertimeAttachmentParams,
	approveOvertimeRequests,
	approveOvertimeRequestsV3,
	cancelOvertimeRequests,
	cancelOvertimeRequestsV3,
	getAvailableOvertimeTypeOptions,
	getAvailableOvertimeTypeOptionsV3,
	getOvertimeAttachmentFile,
	getOvertimeAttachmentFileV3,
	getOvertimeExcelReport,
	getOvertimeExcelReportV3,
	getOvertimeRequest,
	getOvertimeRequestV3,
	getOvertimeRequests,
	getOvertimeRequestsV3,
	rejectOvertimeRequests,
	rejectOvertimeRequestsV3,
	removeOvertimeAttachment,
	removeOvertimeAttachmentV3,
	updateOvertimeRequest,
	updateOvertimeRequestV3,
	uploadOvertimeAttachment,
	uploadOvertimeAttachmentV3,
} from "./endpoints";
import {
	type OvertimeRequest,
	type OvertimeRequestAttachment,
	type OvertimeRequestId,
	type OvertimeRequests,
} from "./types";

const overtimeApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getOvertimeRequest: build.query<
				OvertimeRequest,
				OvertimeRequestId
			>({
				queryFn: async (params) => {
					try {
						const overtimeRequest = await getOvertimeRequest(params);

						return {
							data: overtimeRequest,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: (result, error, overtimeId) => {
					return [
						{
							type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
							id: overtimeId,
						},
					];
				},
			}),
			getOvertimeRequestV3: build.query<
				OvertimeRequest,
				OvertimeRequestId
			>({
				queryFn: async (params) => {
					try {
						const overtimeRequest = await getOvertimeRequestV3(params);

						return {
							data: overtimeRequest,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: (result, error, overtimeId) => {
					return [
						{
							type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
							id: overtimeId,
						},
					];
				},
			}),
			getOvertimeRequests: build.query<
				OvertimeRequests,
				GetOvertimeRequestsParams
			>({
				queryFn: async (params) => {
					try {
						const overtimeRequests = await getOvertimeRequests(params);

						return {
							data: overtimeRequests,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			getOvertimeRequestsV3: build.query<
				OvertimeRequests,
				GetOvertimeRequestsParams
			>({
				queryFn: async (params) => {
					try {
						const overtimeRequests = await getOvertimeRequestsV3(params);

						return {
							data: overtimeRequests,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			cancelOvertimeRequests: build.mutation<
				undefined,
				CancelOvertimeRequestParams
			>({
				queryFn: async (params) => {
					try {
						await cancelOvertimeRequests(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			cancelOvertimeRequestsV3: build.mutation<
				undefined,
				CancelOvertimeRequestParams
			>({
				queryFn: async (params) => {
					try {
						await cancelOvertimeRequestsV3(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			rejectOvertimeRequests: build.mutation<
				undefined,
				RejectOvertimeRequestsParams
			>({
				queryFn: async (params) => {
					try {
						await rejectOvertimeRequests(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			rejectOvertimeRequestsV3: build.mutation<
				undefined,
				RejectOvertimeRequestsParams
			>({
				queryFn: async (params) => {
					try {
						await rejectOvertimeRequestsV3(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			approveOvertimeRequests: build.mutation<
				undefined,
				ApproveOvertimeRequestsParams
			>({
				queryFn: async (params) => {
					try {
						await approveOvertimeRequests(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			approveOvertimeRequestsV3: build.mutation<
				undefined,
				ApproveOvertimeRequestsParams
			>({
				queryFn: async (params) => {
					try {
						await approveOvertimeRequestsV3(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			updateOvertimeRequest: build.mutation<
				undefined,
				OvertimeRequestForUpdate
			>({
				queryFn: async (params) => {
					try {
						await updateOvertimeRequest(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			updateOvertimeRequestV3: build.mutation<
				undefined,
				OvertimeRequestForUpdate
			>({
				queryFn: async (params) => {
					try {
						await updateOvertimeRequestV3(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			getOvertimeExcelReport: build.query<
				GetOvertimeExcelReportResult,
				GetOvertimeExcelReportParams
			>({
				queryFn: async (params) => {
					try {
						const response = await getOvertimeExcelReport(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getOvertimeExcelReportV3: build.query<
				GetOvertimeExcelReportResult,
				GetOvertimeExcelReportParams
			>({
				queryFn: async (params) => {
					try {
						const response = await getOvertimeExcelReportV3(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getAvailableOvertimeTypeOptions: build.query<
				GetAvailableOvertimeTypeOptionsResponse,
				GetAvailableOvertimeTypeOptionsParams
			>({
				queryFn: async (params) => {
					try {
						const response = await getAvailableOvertimeTypeOptions(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getAvailableOvertimeTypeOptionsV3: build.query<
				GetAvailableOvertimeTypeOptionsResponse,
				GetAvailableOvertimeTypeOptionsParams
			>({
				queryFn: async (params) => {
					try {
						const response = await getAvailableOvertimeTypeOptionsV3(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getOvertimeAttachmentFile: build.query<
				GetOvertimeAttachmentFileResult,
				GetOvertimeAttachmentFileParams
			>({
				queryFn: async (params) => {
					try {
						const response = await getOvertimeAttachmentFile(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getOvertimeAttachmentFileV3: build.query<
				GetOvertimeAttachmentFileResult,
				GetOvertimeAttachmentFileParams
			>({
				queryFn: async (params) => {
					try {
						const response = await getOvertimeAttachmentFileV3(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			uploadOvertimeAttachment: build.mutation<
				OvertimeRequestAttachment,
				UploadOvertimeAttachmentParams
			>({
				queryFn: async (params) => {
					try {
						const overtimeRequestAttachment = await uploadOvertimeAttachment(params);

						return {
							data: overtimeRequestAttachment,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			uploadOvertimeAttachmentV3: build.mutation<
				OvertimeRequestAttachment,
				UploadOvertimeAttachmentParams
			>({
				queryFn: async (params) => {
					try {
						const overtimeRequestAttachment = await uploadOvertimeAttachmentV3(params);

						return {
							data: overtimeRequestAttachment,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			removeOvertimeAttachment: build.mutation<
				undefined,
				RemoveOvertimeAttachmentParams
			>({
				queryFn: async (params) => {
					try {
						await removeOvertimeAttachment(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
			removeOvertimeAttachmentV3: build.mutation<
				undefined,
				RemoveOvertimeAttachmentParams
			>({
				queryFn: async (params) => {
					try {
						await removeOvertimeAttachmentV3(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				invalidatesTags: [
					{
						type: ReduxToolkitQueryTag.OVERTIME_REQUESTS,
					},
				],
			}),
		};
	},
});

export {
	overtimeApi,
};
