/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type ArrayResponse,
} from "types/api";

import {
	type EmployeesPresencesInfo,
	type PresenceSettings,
	type PresencesNormForEmployees,
	type PresencesToUpdate,
} from "./types";

type EmployeesPresenceResponse = ArrayResponse<EmployeesPresencesInfo>;

interface GetEmployeesPresenceInfoParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

const getEmployeesPresenceInfo = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetEmployeesPresenceInfoParams): Promise<EmployeesPresencesInfo> => {
	const {
		data: {
			values: employeesPresenceInfo,
		},
	} = await api.get<EmployeesPresenceResponse>(
		"presences",
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return employeesPresenceInfo;
};

type PresenceSettingsResponse = ArrayResponse<PresenceSettings>;

interface GetPresenceSettingsParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

const getPresenceSettings = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetPresenceSettingsParams): Promise<PresenceSettings> => {
	const {
		data: {
			values: presenceSettings,
		},
	} = await api.get<PresenceSettingsResponse>(
		"presences/activities/availability",
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return presenceSettings;
};

const getPresenceSettingsV2 = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetPresenceSettingsParams): Promise<PresenceSettings> => {
	const {
		data: {
			values: presenceSettings,
		},
	} = await api.get<PresenceSettingsResponse>(
		"v2/presences/activities/availability",
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return presenceSettings;
};

interface UpdatePresencesRequestBody {
	presences: PresencesToUpdate;
}

interface UpdatePresencesResponse {
	validationWarnings: string[];
}

const updatePresences = async (
	presencesToUpdate: PresencesToUpdate,
): Promise<UpdatePresencesResponse> => {
	const requestBody: UpdatePresencesRequestBody = {
		presences: presencesToUpdate,
	};

	const {
		data: updatePresencesResponse,
	} = await api.post<UpdatePresencesResponse>(
		"presences/update",
		requestBody,
	);

	return updatePresencesResponse;
};

interface GetPresencesNormForEmployeesParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

interface GetPresencesNormForEmployeesResponse {
	presenceNorm: PresencesNormForEmployees;
}

const getPresencesNormForEmployees = async ({
	periodStartDate,
	periodEndDate,
	employeeIds,
}: GetPresencesNormForEmployeesParams): Promise<PresencesNormForEmployees> => {
	const {
		data: {
			presenceNorm: presenceNormForEmployees,
		},
	} = await api.get<GetPresencesNormForEmployeesResponse>(
		"presences/norm",
		{
			params: {
				startDate: periodStartDate,
				endDate: periodEndDate,
				employeeIds,
			},
		},
	);

	return presenceNormForEmployees;
};

export {
	getEmployeesPresenceInfo,
	getPresenceSettings,
	getPresenceSettingsV2,
	updatePresences,
	getPresencesNormForEmployees,

	type EmployeesPresenceResponse,
	type GetEmployeesPresenceInfoParams,
	type GetPresenceSettingsParams,
	type PresenceSettingsResponse,
	type UpdatePresencesRequestBody,
	type UpdatePresencesResponse,
	type GetPresencesNormForEmployeesParams,
	type GetPresencesNormForEmployeesResponse,
};
