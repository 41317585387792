/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	DECIMAL_BASE,
	PRECISION,
} from "utilities/number";

import {
	type Duration,
} from "../../types";

interface Settings {
	threshold: Duration;
}

const DEFAULT_SETTINGS: Settings = {
	/*
		This value is connected to the `src/utilities/number/round` function's
		precision.
		If a custom precision is used in that function, it is necessary to pass
		a custom threshold as well.
	*/
	threshold: 1 / (DECIMAL_BASE ** PRECISION),
};

const getIsWithinDurationThreshold = (
	duration: Duration,
	settings?: Settings,
): boolean => {
	const mergedSettings: Settings = {
		...DEFAULT_SETTINGS,
		...settings,
	};

	return duration >= mergedSettings.threshold;
};

export {
	getIsWithinDurationThreshold,
};
