/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	ActivityType,
	WorklogStatus,
	WorklogType,
} from "models/comparisons/constants";
import {
	type NonProjectWorklogs,
} from "models/non-project-worklogs/types";
import {
	OvertimeStatus,
} from "models/overtime/constants";

import {
	type ProjectPositionWorklogs,
} from "../types";

interface ConvertNonProjectWorklogsParams {
	worklogs: NonProjectWorklogs;
}

const convertNonProjectWorklogs = ({
	worklogs,
}: ConvertNonProjectWorklogsParams): ProjectPositionWorklogs => {
	return worklogs.map((worklog) => {
		const {
			id,
			date,
			duration,
			isOvertime,
			overtimeStatus,
			employeeUid: employeeId,
		} = worklog;

		return {
			id,
			employeeId,
			date,
			duration,
			activityType: ActivityType.NON_PROJECT,
			worklogType: isOvertime
				? WorklogType.OVERTIME
				: WorklogType.WORK,
			status: isUndefined(overtimeStatus)
				? undefined
				: overtimeStatus === OvertimeStatus.APPROVED
					? WorklogStatus.APPROVED
					: WorklogStatus.SUBMITTED,
		};
	});
};

export {
	convertNonProjectWorklogs,
};
