/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	format,
} from "date-fns";

import {
	DAY_OF_WEEK_NAME_FULL,
} from "../constants";

const toFullWeekDayName = (date: Date): string => {
	return format(date, DAY_OF_WEEK_NAME_FULL);
};

export {
	toFullWeekDayName,
};
