/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	type ProjectId,
} from "models/projects/types";
import {
	getApiError,
} from "utils/api";

import {
	getProjectPermissionsInfo,
	getProjectsPermissionsInfo,
	getUserPermissionsInfo,
} from "./endpoints";
import {
	type ProjectPermissionsInfo,
	type ProjectsPermissionsInfo,
	type UserPermissionsInfo,
} from "./types";

const permissionsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getUserPermissionsInfo: build.query<
				UserPermissionsInfo,
				undefined
			>({
				queryFn: async () => {
					try {
						const userPermissionsInfo = await getUserPermissionsInfo();

						return {
							data: userPermissionsInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectPermissionsInfo: build.query<
				ProjectPermissionsInfo,
				ProjectId
			>({
				queryFn: async (params) => {
					try {
						const projectPermissionsInfo = await getProjectPermissionsInfo(params);

						return {
							data: projectPermissionsInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectsPermissionsInfo: build.query<
				ProjectsPermissionsInfo,
				undefined
			>({
				queryFn: async () => {
					try {
						const projectsPermissionsInfo = await getProjectsPermissionsInfo();

						return {
							data: projectsPermissionsInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	permissionsApi,
};
