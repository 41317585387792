/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type AccountId,
	type ProjectId,
} from "models/projects/types";

import {
	type EmployeesWithReferenceHoursCount,
	type ReferenceWorklogs,
} from "./types";

interface GetEmployeesWithReferenceHoursCountParams extends WithPeriodDates {
	projectId: ProjectId;
}

interface GetEmployeesWithReferenceHoursCountResponse {
	employeeWithReferenceHoursCount: EmployeesWithReferenceHoursCount;
}

const getEmployeesWithReferenceHoursCount = async ({
	projectId,
	periodStartDate,
	periodEndDate,
}: GetEmployeesWithReferenceHoursCountParams): Promise<
	EmployeesWithReferenceHoursCount
> => {
	const {
		data: {
			employeeWithReferenceHoursCount,
		},
	} = await api.get<GetEmployeesWithReferenceHoursCountResponse>(
		"reference-hours/employees-count",
		{
			params: {
				projectId,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return employeeWithReferenceHoursCount;
};

interface GetReferenceWorklogsParams {
	accountId: AccountId;
	employeeIds: EmployeeIds;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

interface GetReferenceWorklogsResponse {
	referenceHoursList: ReferenceWorklogs;
}

const getReferenceWorklogs = async ({
	accountId,
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetReferenceWorklogsParams): Promise<ReferenceWorklogs> => {
	const {
		data: {
			referenceHoursList: referenceWorklogs,
		},
	} = await api.get<GetReferenceWorklogsResponse>(
		"reference-hours",
		{
			params: {
				accountId,
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return referenceWorklogs;
};

interface ReimportOfReferenceHoursParams {
	projectId: ProjectId;
	startDate: DateString;
	endDate: DateString;
}

const reimportOfReferenceHours = async ({
	projectId,
	startDate,
	endDate,
}: ReimportOfReferenceHoursParams): Promise<void> => {
	await api.post(
		"reference-hours/recalculate",
		{
			projectId,
			startDate,
			endDate,
		},
	);
};

export {
	getEmployeesWithReferenceHoursCount,
	getReferenceWorklogs,
	reimportOfReferenceHours,

	type GetEmployeesWithReferenceHoursCountParams,
	type GetEmployeesWithReferenceHoursCountResponse,
	type GetReferenceWorklogsParams,
	type GetReferenceWorklogsResponse,
	type ReimportOfReferenceHoursParams,
};
