/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
enum PresenceStatus {
	APPROVED = "APPROVED",
	REJECTED = "REJECTED",
	SUBMITTED = "SUBMITTED",
	// Exists on FE only.
	PREFILLED = "PREFILLED",
}

enum PresenceType {
	SINGLE = "SINGLE",
	MULTI = "MULTI",
}

enum PresenceActivityType {
	ACTIVITY = "ACTIVITY",
	REST = "REST",
	ON_DUTY = "ON_DUTY",
}

export {
	PresenceType,
	PresenceActivityType,
	PresenceStatus,
};
