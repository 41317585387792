/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ActivityType,
	WorklogStatus,
} from "models/comparisons/constants";
import {
	type EmployeeWorklogs,
} from "models/employee-worklogs/types";
import {
	OvertimeStatus,
} from "models/overtime/constants";

import {
	type UnifiedEmployeeWorklog,
	type UnifiedEmployeeWorklogs,
} from "../types";

const convertEmployeeWorklogs = (
	worklogs: EmployeeWorklogs,
): UnifiedEmployeeWorklogs => {
	return worklogs.map<UnifiedEmployeeWorklog>((worklog) => {
		const {
			date,
			duration,
			projectId,
			employeeUid,
			isBillable,
			isOvertime,
			isExcludedHours,
			isNpa,
			isLeave,
			overtimeStatus,
		} = worklog;

		return {
			date,
			duration,
			employeeId: employeeUid,
			activityType: (
				isLeave
					? ActivityType.LEAVE
					: isNpa
						? ActivityType.NON_PROJECT
						: ActivityType.PROJECT
			),
			isBillable,
			isOvertime,
			isExcludedHours,
			status: (
				isOvertime
					? overtimeStatus === OvertimeStatus.APPROVED
						? WorklogStatus.APPROVED
						: WorklogStatus.SUBMITTED
					: isLeave
						// Back-end sends only taken leaves for the converted interface.
						? WorklogStatus.TAKEN
						: undefined
			),
			projectId,
		};
	});
};

export {
	convertEmployeeWorklogs,
};
