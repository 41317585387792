/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	overtimeApi,
} from "models/overtime/api";
import {
	type OvertimeRequestForStatusChange,
} from "models/overtime/endpoints";
import {
	type OvertimeRequest,
	type OvertimeRequestId,
	type OvertimeRequests,
	type OvertimeStatusChangeReason,
} from "models/overtime/types";
import {
	type Dispatch,
} from "store";

import {
	getOvertimeRequestStatusChangeErrorMessage,
} from "../get-overtime-request-status-change-error-message/get-overtime-request-status-change-error-message";

interface CancelOvertimeRequestsParams {
	isNewPermissionSchemeEnabled: boolean;
	overtimeRequestsToCancel: OvertimeRequests;
	cancelReason: OvertimeStatusChangeReason;
	getOvertimeRequestById: (
		overtimeRequestId: OvertimeRequestId,
	) => OvertimeRequest | undefined;
	dispatch: Dispatch;
}

const cancelOvertimeRequests = async ({
	isNewPermissionSchemeEnabled,
	overtimeRequestsToCancel,
	cancelReason,
	getOvertimeRequestById,
	dispatch,
}: CancelOvertimeRequestsParams): Promise<void> => {
	try {
		const adjustedOvertimeRequestsToCancel = overtimeRequestsToCancel.map<
			OvertimeRequestForStatusChange
		>((overtimeRequest) => {
			const {
				overtimeId,
				overtimeVersion,
			} = overtimeRequest;

			return {
				id: overtimeId,
				overtimeVersion,
			};
		});

		const cancelOvertimeRequestsRequest = (
			isNewPermissionSchemeEnabled
				? overtimeApi.endpoints.cancelOvertimeRequestsV3.initiate
				: overtimeApi.endpoints.cancelOvertimeRequests.initiate
		);

		await dispatch(
			cancelOvertimeRequestsRequest({
				overtimeRequestsToCancel: adjustedOvertimeRequestsToCancel,
				cancelReason,
			}),
		)
			.unwrap();
	} catch (error) {
		const errorMessage = getOvertimeRequestStatusChangeErrorMessage({
			error,
			getErrorMessageForOvertimeRequestId: (notCancelledOvertimeRequestId) => {
				const notCancelledOvertimeRequest = getOvertimeRequestById(
					notCancelledOvertimeRequestId,
				);

				if (isUndefined(notCancelledOvertimeRequest)) {
					return undefined;
				}

				const {
					employeeName,
					date,
				} = notCancelledOvertimeRequest;

				return `The overtime request for ${employeeName} for ${date} can't be cancelled or rejected.`;
			},
		});

		await Promise.reject(new Error(errorMessage));
	}
};

export {
	cancelOvertimeRequests,
};
