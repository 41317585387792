/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ActivityType,
	BillableType,
	WorklogStatus,
	WorklogType,
} from "models/comparisons/constants";
import {
	type ReferenceWorklogs,
} from "models/reference-worklogs/types";

import {
	type ProjectPositionWorklogs,
} from "../types";

interface ConvertReferenceWorklogsParams {
	worklogs: ReferenceWorklogs;
}

const convertReferenceWorklogs = ({
	worklogs,
}: ConvertReferenceWorklogsParams): ProjectPositionWorklogs => {
	return worklogs.map((worklog) => {
		const {
			date,
			duration,
			employeeId,
			trackingSystemName,
			isOvertime,
			isExcludedHours,
			isBillable,
		} = worklog;

		return {
			employeeId,
			date,
			duration,
			activityType: ActivityType.PROJECT,
			worklogType: isExcludedHours
				? WorklogType.ON_DUTY
				: isOvertime
					? WorklogType.OVERTIME
					: WorklogType.WORK,
			billableType: isBillable
				? BillableType.BILLABLE
				: BillableType.NON_BILLABLE,
			status: isOvertime
				? WorklogStatus.APPROVED
				: undefined,
			trackingSystemName,
		};
	});
};

export {
	convertReferenceWorklogs,
};
