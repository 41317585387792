/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	BILLING_TYPES,
	type BillingType,
} from "environment/app_environment";

import {
	type ProjectPositionBillingTypeId,
} from "../types";

const getBillingTypeById = (
	billingTypeId: ProjectPositionBillingTypeId | undefined,
): BillingType | undefined => {
	if (isUndefined(billingTypeId)) {
		return undefined;
	}

	const billingType = BILLING_TYPES.find((currentBillingType) => {
		return currentBillingType.id === Number(billingTypeId);
	});

	return billingType;
};

export {
	getBillingTypeById,
};
