/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReduxToolkitQueryTag,
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetEmployeesPresenceInfoParams,
	type GetPresenceSettingsParams,
	type GetPresencesNormForEmployeesParams,
	type UpdatePresencesResponse,
	getEmployeesPresenceInfo,
	getPresenceSettings,
	getPresenceSettingsV2,
	getPresencesNormForEmployees,
	updatePresences,
} from "./endpoints";
import {
	type EmployeesPresencesInfo,
	type PresenceSettings,
	type PresencesNormForEmployees,
	type PresencesToUpdate,
} from "./types";

const presencesApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getEmployeesPresenceInfo: build.query<
				EmployeesPresencesInfo,
				GetEmployeesPresenceInfoParams
			>({
				queryFn: async (params) => {
					try {
						const employeesPresenceInfo = await getEmployeesPresenceInfo(params);

						return {
							data: employeesPresenceInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.PRESENCES,
						id: "employees-presence-info",
					},
				],
			}),
			getPresenceSettings: build.query<
				PresenceSettings,
				GetPresenceSettingsParams
			>({
				queryFn: async (params) => {
					try {
						const presenceSettings = await getPresenceSettings(params);

						return {
							data: presenceSettings,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getPresenceSettingsV2: build.query<
				PresenceSettings,
				GetPresenceSettingsParams
			>({
				queryFn: async (params) => {
					try {
						const presenceSettings = await getPresenceSettingsV2(params);

						return {
							data: presenceSettings,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			updatePresences: build.mutation<
				UpdatePresencesResponse,
				PresencesToUpdate
			>({
				queryFn: async (params) => {
					try {
						const updatePresencesResponse = await updatePresences(params);

						return {
							data: updatePresencesResponse,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getPresencesNormForEmployees: build.query<
				PresencesNormForEmployees,
				GetPresencesNormForEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const presenceNormForEmployees = await getPresencesNormForEmployees(params);

						return {
							data: presenceNormForEmployees,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	presencesApi,
};
