/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetEmployeesWithReferenceHoursCountParams,
	type GetReferenceWorklogsParams,
	type ReimportOfReferenceHoursParams,
	getEmployeesWithReferenceHoursCount,
	getReferenceWorklogs,
	reimportOfReferenceHours,
} from "./endpoints";
import {
	type EmployeesWithReferenceHoursCount,
	type ReferenceWorklogs,
} from "./types";

const referenceWorklogsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getEmployeesWithReferenceHoursCount: build.query<
				EmployeesWithReferenceHoursCount,
				GetEmployeesWithReferenceHoursCountParams
			>({
				queryFn: async (params) => {
					try {
						const employeesWithReferenceHoursCount = await getEmployeesWithReferenceHoursCount(params);

						return {
							data: employeesWithReferenceHoursCount,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getReferenceWorklogs: build.query<
				ReferenceWorklogs,
				GetReferenceWorklogsParams
			>({
				queryFn: async (params) => {
					try {
						const referenceWorklogs = await getReferenceWorklogs(params);

						return {
							data: referenceWorklogs,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			reimportOfReferenceHours: build.mutation<
				undefined,
				ReimportOfReferenceHoursParams
			>({
				queryFn: async (params) => {
					try {
						await reimportOfReferenceHours(params);

						return {
							data: undefined,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	referenceWorklogsApi,
};
