/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";

import {
	type NonDistributedLeaves,
} from "./types";

interface GetNonDistributedLeavesParams {
	employeeIds: EmployeeIds;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getNonDistributedLeaves = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetNonDistributedLeavesParams): Promise<NonDistributedLeaves> => {
	const {
		data: nonDistributedLeaves,
	} = await api.get<NonDistributedLeaves>(
		"employee/non-distributed-leaves",
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return nonDistributedLeaves;
};

export {
	getNonDistributedLeaves,

	type GetNonDistributedLeavesParams,
};
