/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetProjectWorklogsParams,
	getProjectWorklogs,
} from "./endpoints";
import {
	type ProjectWorklogs,
} from "./types";

const projectWorklogsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProjectWorklogs: build.query<
				ProjectWorklogs,
				GetProjectWorklogsParams
			>({
				queryFn: async (params) => {
					try {
						const projectWorklogs = await getProjectWorklogs(params);

						return {
							data: projectWorklogs,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	projectWorklogsApi,
};
