/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import capitalize from "lodash/capitalize";
import replace from "lodash/replace";
import toLower from "lodash/toLower";

import {
	ReactComponent as DefaultTipIcon,
} from "icons/warning-18.svg";
import {
	PROJECT_TIP_ADV,
} from "pages/project-position-breakdown/constants";
import {
	type ProjectTipAdvList,
	type ProjectTipAdvObj,
} from "pages/project-position-breakdown/types";

import {
	type ProjectTipTypes,
} from "../../tips/types";

// TODO: replace with `toCapitalCase`.
const convertProjectTipName = (tipType: string): string => {
	return capitalize(toLower(replace(tipType, /_/g, " ")));
};

const getFormattedProjectTips = (projectTipTypes: ProjectTipTypes): ProjectTipAdvList => {
	return projectTipTypes.map<ProjectTipAdvObj>((type) => {
		return (
			PROJECT_TIP_ADV[type]
			?? {
				id: type,
				name: convertProjectTipName(type),
				icon: DefaultTipIcon,
			}
		);
	});
};

export {
	getFormattedProjectTips,
};
