/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type ProjectIds,
} from "models/projects/types";
import {
	type ArrayResponse,
} from "types/api";

import {
	type EmployeeProjectDayStatistics,
} from "./types";

interface GetEmployeeProjectDayStatisticsParams extends WithPeriodDates {
	excludeProjectIds: ProjectIds;
	employeeIds: EmployeeIds;
}

type EmployeeProjectDayStatisticsResponse = ArrayResponse<
	EmployeeProjectDayStatistics
>;

const getEmployeeProjectDayStatistics = async ({
	excludeProjectIds,
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetEmployeeProjectDayStatisticsParams): Promise<
	EmployeeProjectDayStatistics
> => {
	const {
		data: {
			values: employeesOtherProjectsInfo,
		},
	} = await api.get<EmployeeProjectDayStatisticsResponse>(
		"employee/employee-project-day-statistics",
		{
			params: {
				excludeProjectIds,
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return employeesOtherProjectsInfo;
};

export {
	getEmployeeProjectDayStatistics,

	type GetEmployeeProjectDayStatisticsParams,
	type EmployeeProjectDayStatisticsResponse,
};
