/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReduxToolkitQueryTag,
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type ChangeMarkupStatusForEmployeesParams,
	type ChangeMarkupStatusForProjectsParams,
	type ChangeMarkupStatusResponse,
	type GetMarkupsActionConfigParams,
	type GetProjectPositionMarkupsParams,
	deleteMarkupStatusForEmployees,
	deleteMarkupStatusForProjects,
	getMarkupsActionConfig,
	getProjectPositionMarkups,
	setMarkupStatusForEmployees,
	setMarkupStatusForProjects,
} from "./endpoints";
import {
	type ProjectPositionMarkups,
	type ProjectPositionMarkupsConfig,
} from "./types";

const projectPositionMarkupsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getMarkupsActionConfig: build.query<
				ProjectPositionMarkupsConfig,
				GetMarkupsActionConfigParams
			>({
				queryFn: async (params) => {
					try {
						const markupsActionConfig = await getMarkupsActionConfig(params);

						return {
							data: markupsActionConfig,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectPositionMarkups: build.query<
				ProjectPositionMarkups,
				GetProjectPositionMarkupsParams
			>({
				queryFn: async (params) => {
					try {
						const projectPositionMarkups = await getProjectPositionMarkups(params);

						return {
							data: projectPositionMarkups,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				providesTags: [
					{
						type: ReduxToolkitQueryTag.MARKUPS,
						id: "project-position-markups",
					},
				],
			}),
			setMarkupStatusForProjects: build.mutation<
				ChangeMarkupStatusResponse,
				ChangeMarkupStatusForProjectsParams
			>({
				queryFn: async (params) => {
					try {
						const response = await setMarkupStatusForProjects(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			deleteMarkupStatusForProjects: build.mutation<
				ChangeMarkupStatusResponse,
				ChangeMarkupStatusForProjectsParams
			>({
				queryFn: async (params) => {
					try {
						const response = await deleteMarkupStatusForProjects(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			setMarkupStatusForEmployees: build.mutation<
				ChangeMarkupStatusResponse,
				ChangeMarkupStatusForEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const response = await setMarkupStatusForEmployees(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			deleteMarkupStatusForEmployees: build.mutation<
				ChangeMarkupStatusResponse,
				ChangeMarkupStatusForEmployeesParams
			>({
				queryFn: async (params) => {
					try {
						const response = await deleteMarkupStatusForEmployees(params);

						return {
							data: response,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	projectPositionMarkupsApi,
};
