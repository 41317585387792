/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ActivityType,
	WorklogStatus,
} from "models/comparisons/constants";
import {
	type EmployeeLeaves,
} from "models/leaves/types";

import {
	type UnifiedEmployeeWorklog,
	type UnifiedEmployeeWorklogs,
} from "../types";

const convertEmployeeLeaves = (
	leaves: EmployeeLeaves,
): UnifiedEmployeeWorklogs => {
	return leaves.map<UnifiedEmployeeWorklog>((leave) => {
		const {
			date,
			duration,
			employeeId,
			isPlanned,
		} = leave;

		return {
			date,
			duration,
			employeeId,
			activityType: ActivityType.LEAVE,
			status: isPlanned
				? WorklogStatus.REQUESTED
				: WorklogStatus.TAKEN,
		};
	});
};

export {
	convertEmployeeLeaves,
};
