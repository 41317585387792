/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import join from "lodash/join";
import toString from "lodash/toString";

import {
	api,
} from "constants/api";
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type ProjectPositionIds,
} from "models/project-positions/types";
import {
	type ProjectIds,
} from "models/projects/types";

import {
	type MarkupPeriod,
} from "./constants";
import {
	type ChangeMarkupStatusWarnings,
	type ProjectPositionMarkups,
	type ProjectPositionMarkupsConfig,
	type StatusId,
	type WorklogMarkupInfo,
} from "./types";

interface GetFormDataParams {
	projectIds: ProjectIds;
	employeeIds: EmployeeIds;
	statusId: StatusId;
	periodType: MarkupPeriod;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getFormData = ({
	projectIds,
	employeeIds,
	statusId,
	periodType,
	periodStartDate,
	periodEndDate,
}: GetFormDataParams): FormData => {
	const formData = new FormData();

	formData.append("projectUids", join(projectIds));

	formData.append("employeeUids", join(employeeIds));

	formData.append("statusId", toString(statusId));

	formData.append("periodType", periodType);

	formData.append("from", periodStartDate);

	formData.append("to", periodEndDate);

	return formData;
};

interface GetMarkupsActionConfigParams {
	startDate: DateString;
	endDate: DateString;
	positionIds: ProjectPositionIds;
}

interface GetMarkupsActionConfigResponse {
	markupStatusConfigInfoEmployeeResponseList: ProjectPositionMarkupsConfig;
}

const getMarkupsActionConfig = async ({
	positionIds,
	startDate,
	endDate,
}: GetMarkupsActionConfigParams): Promise<ProjectPositionMarkupsConfig> => {
	const {
		data: {
			markupStatusConfigInfoEmployeeResponseList: markupsConfig,
		},
	} = await api.post<GetMarkupsActionConfigResponse>(
		"markup/status-config",
		{
			positionIds,
			startDate,
			endDate,
		},
	);

	return markupsConfig;
};

interface GetProjectPositionMarkupsParams {
	projectUids: ProjectIds;
	employeeUids: EmployeeIds;
	startDate: DateString;
	endDate: DateString;
}

interface GetProjectPositionMarkupsResponse {
	markedWorklogDtoList: ProjectPositionMarkups;
}

const getProjectPositionMarkups = async ({
	projectUids,
	employeeUids,
	startDate,
	endDate,
}: GetProjectPositionMarkupsParams): Promise<ProjectPositionMarkups> => {
	const {
		data: {
			markedWorklogDtoList: markups,
		},
	} = await api.post<GetProjectPositionMarkupsResponse>(
		"markup/marked-worklog",
		{
			projectUids,
			employeeUids,
			startDate,
			endDate,
		},
	);

	return markups;
};

interface ChangeMarkupStatusForProjectsParams {
	projectIds: ProjectIds;
	employeeIds?: EmployeeIds;
	statusId: StatusId;
	periodType: MarkupPeriod;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

interface ChangeMarkupStatusResponse {
	warnings: ChangeMarkupStatusWarnings;
	worklogMarkupShortInfo: WorklogMarkupInfo[];
}

const setMarkupStatusForProjects = async ({
	projectIds,
	employeeIds = [],
	statusId,
	periodType,
	periodStartDate,
	periodEndDate,
}: ChangeMarkupStatusForProjectsParams): Promise<ChangeMarkupStatusResponse> => {
	const formData = getFormData({
		projectIds,
		employeeIds,
		statusId,
		periodType,
		periodStartDate,
		periodEndDate,
	});

	const {
		data: response,
	} = await api.post<ChangeMarkupStatusResponse>(
		"markup/worklog/project",
		formData,
	);

	return response;
};

const deleteMarkupStatusForProjects = async ({
	projectIds,
	employeeIds = [],
	statusId,
	periodType,
	periodStartDate,
	periodEndDate,
}: ChangeMarkupStatusForProjectsParams): Promise<ChangeMarkupStatusResponse> => {
	const formData = getFormData({
		projectIds,
		employeeIds,
		statusId,
		periodType,
		periodStartDate,
		periodEndDate,
	});

	const {
		data: response,
	} = await api.delete<ChangeMarkupStatusResponse>(
		"markup/worklog/project",
		{
			data: formData,
		},
	);

	return response;
};

interface ChangeMarkupStatusForEmployeesParams extends
	ChangeMarkupStatusForProjectsParams {
	employeeIds: EmployeeIds;
}

const setMarkupStatusForEmployees = async ({
	projectIds,
	employeeIds,
	statusId,
	periodType,
	periodStartDate,
	periodEndDate,
}: ChangeMarkupStatusForEmployeesParams): Promise<ChangeMarkupStatusResponse> => {
	const formData = getFormData({
		projectIds,
		employeeIds,
		statusId,
		periodType,
		periodStartDate,
		periodEndDate,
	});

	const {
		data: response,
	} = await api.post<ChangeMarkupStatusResponse>(
		"markup/worklog/employee",
		formData,
	);

	return response;
};

const deleteMarkupStatusForEmployees = async ({
	projectIds,
	employeeIds,
	statusId,
	periodType,
	periodStartDate,
	periodEndDate,
}: ChangeMarkupStatusForEmployeesParams): Promise<ChangeMarkupStatusResponse> => {
	const formData = getFormData({
		projectIds,
		employeeIds,
		statusId,
		periodType,
		periodStartDate,
		periodEndDate,
	});

	const {
		data: response,
	} = await api.delete<ChangeMarkupStatusResponse>(
		"markup/worklog/employee",
		{
			data: formData,
		},
	);

	return response;
};

export {
	getMarkupsActionConfig,
	getProjectPositionMarkups,
	setMarkupStatusForProjects,
	deleteMarkupStatusForProjects,
	setMarkupStatusForEmployees,
	deleteMarkupStatusForEmployees,

	type GetMarkupsActionConfigParams,
	type GetProjectPositionMarkupsParams,
	type ChangeMarkupStatusForProjectsParams,
	type ChangeMarkupStatusResponse,
	type ChangeMarkupStatusForEmployeesParams,
};
