/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type ArrayResponse,
} from "types/api";

import {
	type NonProjectActivityAssignments,
} from "./types";

interface GetNonProjectActivityAssignmentsParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

type NonProjectActivityAssignmentsResponse = ArrayResponse<
	NonProjectActivityAssignments
>;

const getNonProjectActivityAssignments = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetNonProjectActivityAssignmentsParams): Promise<
	NonProjectActivityAssignments
> => {
	const {
		data: {
			values: nonProjectActivityAssignments,
		},
	} = await api.get<NonProjectActivityAssignmentsResponse>(
		"employees/assignments/npa",
		{
			params: {
				employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
			},
		},
	);

	return nonProjectActivityAssignments;
};

export {
	getNonProjectActivityAssignments,

	type GetNonProjectActivityAssignmentsParams,
	type NonProjectActivityAssignmentsResponse,
};
