/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	getProblemsNotificationInfo,
} from "./endpoints";
import {
	type ProblemsNotificationInfo,
} from "./types";

const problemsNotificationApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProblemsNotificationInfo: build.query<
				ProblemsNotificationInfo | undefined,
				undefined
			>({
				queryFn: async () => {
					try {
						const problemsNotificationInfo = await getProblemsNotificationInfo();

						return {
							data: problemsNotificationInfo,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	problemsNotificationApi,
};
