/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type HttpStatusCode,
} from "axios";

import {
	api,
} from "constants/api";
import {
	type DateString,
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeId,
} from "models/employees/types";
import {
	type ArrayResponse,
} from "types/api";

import {
	type OvertimeStatus,
} from "./constants";
import {
	type OvertimeRequest,
	type OvertimeRequestAttachment,
	type OvertimeRequestAttachmentId,
	type OvertimeRequestId,
	type OvertimeRequestIds,
	type OvertimeRequestVersion,
	type OvertimeRequests,
	type OvertimeStatusChangeReason,
	type OvertimeTypeOptions,
	type ProhibitedOvertimeTypesInfo,
	type TimePackageId,
} from "./types";

const getOvertimeRequest = async (
	overtimeRequestId: OvertimeRequestId,
): Promise<OvertimeRequest> => {
	const {
		data: overtimeRequest,
	} = await api.get<OvertimeRequest>(
		`overtime/${overtimeRequestId}`,
	);

	return overtimeRequest;
};

const getOvertimeRequestV3 = async (
	overtimeRequestId: OvertimeRequestId,
): Promise<OvertimeRequest> => {
	const {
		data: overtimeRequest,
	} = await api.get<OvertimeRequest>(
		`v3/overtime/${overtimeRequestId}`,
	);

	return overtimeRequest;
};

interface GetOvertimeRequestsParams extends WithPeriodDates {
	shouldIncludeDirectEmployeesOnly: boolean;
	overtimeRequestIds?: OvertimeRequestIds;
}

type GetOvertimeRequestsResponse = ArrayResponse<OvertimeRequests>;

const getOvertimeRequests = async ({
	periodStartDate,
	periodEndDate,
	shouldIncludeDirectEmployeesOnly,
	overtimeRequestIds,
}: GetOvertimeRequestsParams): Promise<OvertimeRequests> => {
	const {
		data: {
			values: overtimeRequests,
		},
	} = await api.get<GetOvertimeRequestsResponse>(
		"overtime",
		{
			params: {
				from: periodStartDate,
				to: periodEndDate,
				onlyDirectMembers: shouldIncludeDirectEmployeesOnly,
				overtimeIds: overtimeRequestIds,
			},
		},
	);

	return overtimeRequests;
};

const getOvertimeRequestsV3 = async ({
	periodStartDate,
	periodEndDate,
	shouldIncludeDirectEmployeesOnly,
	overtimeRequestIds,
}: GetOvertimeRequestsParams): Promise<OvertimeRequests> => {
	const {
		data: {
			values: overtimeRequests,
		},
	} = await api.get<GetOvertimeRequestsResponse>(
		"v3/overtime",
		{
			params: {
				from: periodStartDate,
				to: periodEndDate,
				onlyDirectMembers: shouldIncludeDirectEmployeesOnly,
				overtimeIds: overtimeRequestIds,
			},
		},
	);

	return overtimeRequests;
};

interface OvertimeRequestForStatusChange {
	id: OvertimeRequestId;
	overtimeVersion: OvertimeRequestVersion;
}

type OvertimeRequestsForStatusChange = OvertimeRequestForStatusChange[];

interface ChangeOvertimeRequestsStatusBulkRequestBody {
	overtimeList: OvertimeRequestsForStatusChange;
}

interface WithChangeReason {
	reason: OvertimeStatusChangeReason;
}

interface ChangeOvertimeRequestsStatusBulkRequestBodyWithChangeReason extends
	ChangeOvertimeRequestsStatusBulkRequestBody,
	WithChangeReason {}

interface CancelOvertimeRequestParams {
	overtimeRequestsToCancel: OvertimeRequestsForStatusChange;
	cancelReason: OvertimeStatusChangeReason;
}

const cancelOvertimeRequests = async ({
	overtimeRequestsToCancel,
	cancelReason,
}: CancelOvertimeRequestParams): Promise<void> => {
	const requestBody: ChangeOvertimeRequestsStatusBulkRequestBodyWithChangeReason = {
		overtimeList: overtimeRequestsToCancel,
		reason: cancelReason,
	};

	await api.post(
		"overtime/cancel",
		requestBody,
	);
};

const cancelOvertimeRequestsV3 = async ({
	overtimeRequestsToCancel,
	cancelReason,
}: CancelOvertimeRequestParams): Promise<void> => {
	const requestBody: ChangeOvertimeRequestsStatusBulkRequestBodyWithChangeReason = {
		overtimeList: overtimeRequestsToCancel,
		reason: cancelReason,
	};

	await api.post(
		"v3/overtime/cancel",
		requestBody,
	);
};

interface RejectOvertimeRequestsParams {
	overtimeRequestsToReject: OvertimeRequestsForStatusChange;
	rejectReason: OvertimeStatusChangeReason;
}

const rejectOvertimeRequests = async ({
	overtimeRequestsToReject,
	rejectReason,
}: RejectOvertimeRequestsParams): Promise<void> => {
	const requestBody: ChangeOvertimeRequestsStatusBulkRequestBodyWithChangeReason = {
		overtimeList: overtimeRequestsToReject,
		reason: rejectReason,
	};

	await api.post(
		"overtime/reject",
		requestBody,
	);
};

const rejectOvertimeRequestsV3 = async ({
	overtimeRequestsToReject,
	rejectReason,
}: RejectOvertimeRequestsParams): Promise<void> => {
	const requestBody: ChangeOvertimeRequestsStatusBulkRequestBodyWithChangeReason = {
		overtimeList: overtimeRequestsToReject,
		reason: rejectReason,
	};

	await api.post(
		"v3/overtime/reject",
		requestBody,
	);
};

interface ApproveOvertimeRequestsParams {
	overtimeRequestsToApprove: OvertimeRequestsForStatusChange;
}

const approveOvertimeRequests = async ({
	overtimeRequestsToApprove,
}: ApproveOvertimeRequestsParams): Promise<void> => {
	const requestBody: ChangeOvertimeRequestsStatusBulkRequestBody = {
		overtimeList: overtimeRequestsToApprove,
	};

	await api.post(
		"overtime/approve",
		requestBody,
	);
};

const approveOvertimeRequestsV3 = async ({
	overtimeRequestsToApprove,
}: ApproveOvertimeRequestsParams): Promise<void> => {
	const requestBody: ChangeOvertimeRequestsStatusBulkRequestBody = {
		overtimeList: overtimeRequestsToApprove,
	};

	await api.post(
		"v3/overtime/approve",
		requestBody,
	);
};

interface OvertimeRequestForUpdate extends OvertimeRequest {
	newStatus?: OvertimeStatus;
}

interface OvertimeRequestToUpdate extends Pick<
	OvertimeRequest,
	| "date"
	| "overtimeId"
	| "overtimeType"
> {
	id: OvertimeRequest["worklogId"];
	comment: OvertimeRequest["description"];
	/*
		Actually, the real value is string, but it will be fixed after the
		`OvertimeView` is rewritten.
	*/
	hours: OvertimeRequest["duration"];
	overtimeStatus: OvertimeRequest["status"];
	previousOvertimeStatus: OvertimeRequest["status"];
	version: OvertimeRequest["overtimeVersion"];
}

const updateOvertimeRequest = async (
	overtimeRequest: OvertimeRequestForUpdate,
): Promise<void> => {
	const {
		worklogId,
		description,
		date,
		duration,
		overtimeId,
		status,
		newStatus,
		overtimeType,
		overtimeVersion,
	} = overtimeRequest;

	const overtimeRequestToUpdate: OvertimeRequestToUpdate = {
		id: worklogId,
		comment: description,
		date,
		hours: duration,
		overtimeId,
		overtimeStatus: newStatus ?? status,
		previousOvertimeStatus: status,
		overtimeType,
		version: overtimeVersion,
	};

	await api.patch(
		`overtime/${overtimeId}`,
		overtimeRequestToUpdate,
	);
};

const updateOvertimeRequestV3 = async (
	overtimeRequest: OvertimeRequestForUpdate,
): Promise<void> => {
	const {
		worklogId,
		description,
		date,
		duration,
		overtimeId,
		status,
		newStatus,
		overtimeType,
		overtimeVersion,
	} = overtimeRequest;

	const overtimeRequestToUpdate: OvertimeRequestToUpdate = {
		id: worklogId,
		comment: description,
		date,
		hours: duration,
		overtimeId,
		overtimeStatus: newStatus ?? status,
		previousOvertimeStatus: status,
		overtimeType,
		version: overtimeVersion,
	};

	await api.patch(
		`v3/overtime/${overtimeId}`,
		overtimeRequestToUpdate,
	);
};

interface GetOvertimeExcelReportParams extends WithPeriodDates {
	overtimeRequestIds: OvertimeRequestIds;
}

interface GetOvertimeExcelReportResult {
	overtimeExcelReport: Blob;
	responseStatus: HttpStatusCode;
	responseHeaders: unknown;
}

const getOvertimeExcelReport = async ({
	overtimeRequestIds,
	periodStartDate,
	periodEndDate,
}: GetOvertimeExcelReportParams): Promise<GetOvertimeExcelReportResult> => {
	const {
		data: overtimeExcelReport,
		status: responseStatus,
		headers: responseHeaders,
	} = await api.post<Blob>(
		"excel/overtime",
		overtimeRequestIds,
		{
			params: {
				from: periodStartDate,
				to: periodEndDate,
			},
			responseType: "blob",
			headers: {
				Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		},
	);

	return {
		overtimeExcelReport,
		responseStatus,
		responseHeaders,
	};
};

const getOvertimeExcelReportV3 = async ({
	overtimeRequestIds,
	periodStartDate,
	periodEndDate,
}: GetOvertimeExcelReportParams): Promise<GetOvertimeExcelReportResult> => {
	const {
		data: overtimeExcelReport,
		status: responseStatus,
		headers: responseHeaders,
	} = await api.post<Blob>(
		"v3/excel/overtime",
		overtimeRequestIds,
		{
			params: {
				from: periodStartDate,
				to: periodEndDate,
			},
			responseType: "blob",
			headers: {
				Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			},
		},
	);

	return {
		overtimeExcelReport,
		responseStatus,
		responseHeaders,
	};
};

interface GetAvailableOvertimeTypeOptionsParams {
	employeeId: EmployeeId;
	timePackageId: TimePackageId;
	date: DateString;
}

interface GetAvailableOvertimeTypeOptionsResponse {
	availableOvertimes: OvertimeTypeOptions;
	prohibitedOvertimeTypes: ProhibitedOvertimeTypesInfo;
}

const getAvailableOvertimeTypeOptions = async ({
	employeeId,
	timePackageId,
	date,
}: GetAvailableOvertimeTypeOptionsParams): Promise<
	GetAvailableOvertimeTypeOptionsResponse
> => {
	const {
		data: availableOvertimeTypeOptionsResponse,
	} = await api.get<GetAvailableOvertimeTypeOptionsResponse>(
		"overtime-type/availability",
		{
			params: {
				employeeUid: employeeId,
				timePackageId,
				timesheetDate: date,
			},
		},
	);

	return availableOvertimeTypeOptionsResponse;
};

const getAvailableOvertimeTypeOptionsV3 = async ({
	employeeId,
	timePackageId,
	date,
}: GetAvailableOvertimeTypeOptionsParams): Promise<
	GetAvailableOvertimeTypeOptionsResponse
> => {
	const {
		data: availableOvertimeTypesResponse,
	} = await api.get<GetAvailableOvertimeTypeOptionsResponse>(
		"v3/overtime-type/availability",
		{
			params: {
				employeeUid: employeeId,
				timePackageId,
				timesheetDate: date,
			},
		},
	);

	return availableOvertimeTypesResponse;
};

interface GetOvertimeAttachmentFileParams {
	overtimeRequestId: OvertimeRequestId;
	attachmentId: OvertimeRequestAttachmentId;
}

interface GetOvertimeAttachmentFileResult {
	attachmentFile: Blob;
	responseStatus: HttpStatusCode;
	responseHeaders: unknown;
}

const getOvertimeAttachmentFile = async ({
	overtimeRequestId,
	attachmentId,
}: GetOvertimeAttachmentFileParams): Promise<GetOvertimeAttachmentFileResult> => {
	const {
		data: attachmentFile,
		status: responseStatus,
		headers: responseHeaders,
	} = await api.get<Blob>(
		`overtime/${overtimeRequestId}/attachments/${attachmentId}`,
		{
			responseType: "blob",
		},
	);

	return {
		attachmentFile,
		responseStatus,
		responseHeaders,
	};
};

const getOvertimeAttachmentFileV3 = async ({
	overtimeRequestId,
	attachmentId,
}: GetOvertimeAttachmentFileParams): Promise<GetOvertimeAttachmentFileResult> => {
	const {
		data: attachmentFile,
		status: responseStatus,
		headers: responseHeaders,
	} = await api.get<Blob>(
		`v3/overtime/${overtimeRequestId}/attachments/${attachmentId}`,
		{
			responseType: "blob",
		},
	);

	return {
		attachmentFile,
		responseStatus,
		responseHeaders,
	};
};

interface UploadOvertimeAttachmentParams {
	overtimeRequestId: OvertimeRequestId;
	attachmentFile: File;
}

const uploadOvertimeAttachment = async ({
	overtimeRequestId,
	attachmentFile,
}: UploadOvertimeAttachmentParams): Promise<OvertimeRequestAttachment> => {
	const attachmentFormData = new FormData();

	attachmentFormData.append("file", attachmentFile);

	const {
		data: overtimeRequestAttachment,
	} = await api.post<OvertimeRequestAttachment>(
		`overtime/${overtimeRequestId}/attachments`,
		attachmentFormData,
	);

	return overtimeRequestAttachment;
};

const uploadOvertimeAttachmentV3 = async ({
	overtimeRequestId,
	attachmentFile,
}: UploadOvertimeAttachmentParams): Promise<OvertimeRequestAttachment> => {
	const attachmentFormData = new FormData();

	attachmentFormData.append("file", attachmentFile);

	const {
		data: overtimeRequestAttachment,
	} = await api.post<OvertimeRequestAttachment>(
		`v3/overtime/${overtimeRequestId}/attachments`,
		attachmentFormData,
	);

	return overtimeRequestAttachment;
};

interface RemoveOvertimeAttachmentParams {
	overtimeRequestId: OvertimeRequestId;
	attachmentId: OvertimeRequestAttachmentId;
}

const removeOvertimeAttachment = async ({
	overtimeRequestId,
	attachmentId,
}: RemoveOvertimeAttachmentParams): Promise<void> => {
	await api.delete(
		`overtime/${overtimeRequestId}/attachments/${attachmentId}`,
	);
};

const removeOvertimeAttachmentV3 = async ({
	overtimeRequestId,
	attachmentId,
}: RemoveOvertimeAttachmentParams): Promise<void> => {
	await api.delete(
		`v3/overtime/${overtimeRequestId}/attachments/${attachmentId}`,
	);
};

export {
	getOvertimeRequest,
	getOvertimeRequestV3,
	getOvertimeRequests,
	getOvertimeRequestsV3,
	cancelOvertimeRequests,
	cancelOvertimeRequestsV3,
	rejectOvertimeRequests,
	rejectOvertimeRequestsV3,
	approveOvertimeRequests,
	approveOvertimeRequestsV3,
	updateOvertimeRequest,
	updateOvertimeRequestV3,
	getOvertimeExcelReport,
	getOvertimeExcelReportV3,
	getAvailableOvertimeTypeOptions,
	getAvailableOvertimeTypeOptionsV3,
	getOvertimeAttachmentFile,
	getOvertimeAttachmentFileV3,
	uploadOvertimeAttachment,
	uploadOvertimeAttachmentV3,
	removeOvertimeAttachment,
	removeOvertimeAttachmentV3,

	type GetOvertimeRequestsParams,
	type GetOvertimeRequestsResponse,
	type CancelOvertimeRequestParams,
	type OvertimeRequestForStatusChange,
	type OvertimeRequestsForStatusChange,
	type ChangeOvertimeRequestsStatusBulkRequestBody,
	type ChangeOvertimeRequestsStatusBulkRequestBodyWithChangeReason,
	type RejectOvertimeRequestsParams,
	type ApproveOvertimeRequestsParams,
	type OvertimeRequestForUpdate,
	type OvertimeRequestToUpdate,
	type GetOvertimeExcelReportParams,
	type GetOvertimeExcelReportResult,
	type GetAvailableOvertimeTypeOptionsParams,
	type GetAvailableOvertimeTypeOptionsResponse,
	type GetOvertimeAttachmentFileParams,
	type GetOvertimeAttachmentFileResult,
	type UploadOvertimeAttachmentParams,
	type RemoveOvertimeAttachmentParams,
};
