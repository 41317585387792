/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";

import {
	KPSRoleId,
	KPS_ROLE,
} from "../constants";
import {
	type KPSTeamInfo,
	type Project,
} from "../types";

const getKPSTeamInfo = (project: Project): KPSTeamInfo => {
	const kpsTeamInfoDraft = pick(project, Object.values(KPSRoleId));

	return mapValues(kpsTeamInfoDraft, (kpsMemberEmployeeId, kpsRoleId: KPSRoleId) => {
		return {
			/*
				Field in `project`, which are picked above, are defined as optional.
				If this callback is called, it means, that there is at least one key,
				thus the value is provided and can't be `undefined`.
			*/
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			id: kpsMemberEmployeeId!,
			role: KPS_ROLE[kpsRoleId],
		};
	});
};

export {
	getKPSTeamInfo,
};
