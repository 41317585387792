/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
// Importing a type from Lodash is fine.
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
	type ListIterateeCustom,
} from "lodash";
import filter from "lodash/filter";
import sumBy from "lodash/sumBy";

import {
	type DateString,
	type Duration,
	type PeriodDates,
} from "models/dates-and-time/types";
import {
	toDateString,
} from "models/dates-and-time/utils/to-date-string";

import {
	type DurationByDates,
} from "../types";

interface WorklogForTotalDurationCalculation {
	date: DateString;
	duration: Duration;
}

type GetFilter<WorklogType extends WorklogForTotalDurationCalculation> = (
	date: DateString
	// TODO: return plain function interface.
) => ListIterateeCustom<WorklogType, boolean>;

interface GetDurationByDatesWithTotalDurationParams<
	WorklogType extends WorklogForTotalDurationCalculation,
> {
	periodDates: PeriodDates;
	worklogs: WorklogType[];
	getFilter?: GetFilter<WorklogType>;
}

const defaultFilter: GetFilter<
	WorklogForTotalDurationCalculation
> = (date) => {
	return {
		date,
	};
};

interface DurationByDatesWithTotalDuration extends DurationByDates {
	totalDuration: Duration;
}

const getDurationByDatesWithTotalDuration = <
	WorklogType extends WorklogForTotalDurationCalculation,
>(
	params: GetDurationByDatesWithTotalDurationParams<WorklogType>,
): DurationByDatesWithTotalDuration => {
	const {
		periodDates,
		worklogs,
		getFilter = defaultFilter,
	} = params;

	return periodDates.reduce<DurationByDatesWithTotalDuration>(
		(currentTotalDuration, date) => {
			const dateString = toDateString(date);

			const worklogsForDate = filter(
				worklogs,
				getFilter(dateString),
			);

			const totalDurationForDate = sumBy(
				// TODO: remove type casting when `getFilter` type is fixed.
				worklogsForDate as WorklogType[],
				(worklog) => {
					return worklog.duration;
				},
			);

			return {
				...currentTotalDuration,
				[dateString]: totalDurationForDate,
				totalDuration: currentTotalDuration.totalDuration + totalDurationForDate,
			};
		},
		{
			totalDuration: 0,
		},
	);
};

export {
	getDurationByDatesWithTotalDuration,
	type WorklogForTotalDurationCalculation,
};
