/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";

import {
	type ComparisonSettingsType,
} from "./constants";
import {
	type ComparisonSettings,
} from "./types";

interface GetComparisonSettingsResponse {
	comparisons: ComparisonSettings;
}

const getComparisonSettings = async (
	comparisonSettingsType: ComparisonSettingsType,
): Promise<ComparisonSettings> => {
	const {
		data: {
			comparisons: comparisonSettings,
		},
	} = await api.get<GetComparisonSettingsResponse>(
		"settings/comparison",
		{
			params: {
				type: comparisonSettingsType,
			},
		},
	);

	return comparisonSettings;
};

export {
	getComparisonSettings,

	type GetComparisonSettingsResponse,
};
