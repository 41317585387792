/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	addDays,
	addMonths,
	addYears,
	differenceInDays,
	differenceInMinutes,
	differenceInMonths,
	endOfDay,
	endOfISOWeek,
	endOfMonth,
	format,
	getDate,
	getDay,
	getISODay,
	getMonth,
	getYear,
	isAfter,
	isBefore,
	isEqual,
	isSameDay,
	isValid,
	max,
	min,
	parseISO,
	setDayOfYear,
	setHours,
	setMinutes,
	setMonth,
	setSeconds,
	setYear,
	startOfDay,
	startOfISOWeek,
	startOfMonth,
	subDays,
	subMonths,
	subWeeks,
	subYears,
} from "date-fns";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import map from "lodash/map";
import reject from "lodash/reject";

import {
	CHOOSE_DATE_FORMAT,
	CUSTOM_PERIOD,
	DATE_FULL_MONTH_FORMAT,
	DEFAULT_DATE_FORMAT,
	DEFAULT_TIME_DATE_FORMAT,
	JOURNAL_HEADER_DATE_FORMAT,
	LOCAL_SLASH_FORMAT,
	LOCK_DATE_FORMAT,
	MARKUP_STATUS_DATE_FORMAT,
	MAX_DAY_IN_MONTH,
	Operation,
	PERIODS,
	PICKER_INPUT_DATE_FORMAT,
} from "models/dates-and-time/constants";

import {
	type DateStrings,
} from "../types";

type DateParam = string | Date | number;

const parseISODate = (date: DateParam): Date => {
	if (isString(date)) {
		const parsedDate = parseISO(date);

		/**
		 * Function accepts complete ISO 8601 formats as well as partial implementations.
		 * https://date-fns.org/v2.29.1/docs/parseISO
		 */
		if (isValid(parsedDate)) {
			return parsedDate;
		}

		return new Date(date);
	}

	if (isNumber(date)) {
		return new Date(date);
	}

	return date;
};

interface CompareDatesParams {
	date: DateParam;
	dateToCompare: DateParam;
}

const isDateEqual = ({
	date,
	dateToCompare,
}: CompareDatesParams): boolean => {
	const parsedDate = parseISODate(date);
	const parsedDateToCompare = parseISODate(dateToCompare);

	return isEqual(parsedDate, parsedDateToCompare);
};

const isDateBefore = ({
	date,
	dateToCompare,
}: CompareDatesParams): boolean => {
	const parsedDate = parseISODate(date);
	const parsedDateToCompare = parseISODate(dateToCompare);

	return isBefore(parsedDate, parsedDateToCompare);
};

const isDateAfter = ({
	date,
	dateToCompare,
}: CompareDatesParams): boolean => {
	const parsedDate = parseISODate(date);
	const parsedDateToCompare = parseISODate(dateToCompare);

	return isAfter(parsedDate, parsedDateToCompare);
};

const isEqualOrBefore = ({
	date,
	dateToCompare,
}: CompareDatesParams): boolean => {
	return (
		isDateEqual({
			date,
			dateToCompare,
		})
		|| isDateBefore({
			date,
			dateToCompare,
		})
	);
};

const isEqualOrAfter = ({
	date,
	dateToCompare,
}: CompareDatesParams): boolean => {
	return (
		isDateEqual({
			date,
			dateToCompare,
		})
		|| isDateAfter({
			date,
			dateToCompare,
		})
	);
};

const isDateSameDay = ({
	date,
	dateToCompare,
}: CompareDatesParams): boolean => {
	const parsedDate = parseISODate(date);
	const parsedDateToCompare = parseISODate(dateToCompare);

	return isSameDay(parsedDate, parsedDateToCompare);
};

interface GetDifferenceParams {
	date: DateParam;
	dateToCompare: DateParam;
}

type GetDifferenceInResult = number;

const getDifferenceInMinutes = ({
	date,
	dateToCompare,
}: GetDifferenceParams): GetDifferenceInResult => {
	const parsedDate = parseISODate(date);
	const parsedDateToCompare = parseISODate(dateToCompare);

	return differenceInMinutes(parsedDate, parsedDateToCompare);
};

const getDifferenceInDays = ({
	date,
	dateToCompare,
}: GetDifferenceParams): GetDifferenceInResult => {
	const parsedDate = parseISODate(date);
	const parsedDateToCompare = parseISODate(dateToCompare);

	return differenceInDays(parsedDate, parsedDateToCompare);
};

const getDifferenceInMonths = ({
	date,
	dateToCompare,
}: GetDifferenceParams): GetDifferenceInResult => {
	const parsedDate = parseISODate(date);
	const parsedDateToCompare = parseISODate(dateToCompare);

	return differenceInMonths(parsedDate, parsedDateToCompare);
};

type GetFormattedDatesArrayReturn = null | number[];

/*
	Removes `null` and `undefined` values from the dates array
	and converts dates to number format.
*/
const getFormattedDatesArray = (
	dates: DateStrings,
): GetFormattedDatesArrayReturn => {
	const filteredDatesArray = reject(dates, isNil);

	if (isEmpty(filteredDatesArray)) {
		return null;
	}

	return map(filteredDatesArray, (date) => {
		return parseISODate(date).getTime();
	});
};

type GetMinMaxDateFromArrayResult = Date | null;

const getMinDate = (dates: DateStrings): GetMinMaxDateFromArrayResult => {
	const formattedDates = getFormattedDatesArray(dates);

	if (isNull(formattedDates)) {
		return null;
	}

	return min(formattedDates);
};

const getMaxDate = (dates: DateStrings): GetMinMaxDateFromArrayResult => {
	const formattedDates = getFormattedDatesArray(dates);

	if (isNull(formattedDates)) {
		return null;
	}

	return max(formattedDates);
};

interface DateDaysMethodsParams {
	date: DateParam;
	amount?: number;
}

const getISOWeekDay = (date: DateParam): number => {
	return getISODay(parseISODate(date));
};

const getDayOfWeek = (date: DateParam): number => {
	return getDay(parseISODate(date));
};

const getDayOfMonth = (date: DateParam): number => {
	return getDate(parseISODate(date));
};

const getMonthNumber = (date: DateParam): number => {
	return getMonth(parseISODate(date));
};

const getDateYear = (date: DateParam): number => {
	return getYear(parseISODate(date));
};

interface SetDateParams {
	date: DateParam;
	value: number;
}

const setDateDayOfYear = ({
	date,
	value,
}: SetDateParams): Date => {
	return setDayOfYear(parseISODate(date), value);
};

const setDateMonth = ({
	date,
	value,
}: SetDateParams): Date => {
	return setMonth(parseISODate(date), value);
};

const setDateYear = ({
	date,
	value,
}: SetDateParams): Date => {
	return setYear(parseISODate(date), value);
};

const addDateDays = ({
	date,
	amount = 1,
}: DateDaysMethodsParams): Date => {
	const parsedDate = parseISODate(date);

	return addDays(parsedDate, amount);
};

const subDateDays = ({
	date,
	amount = 1,
}: DateDaysMethodsParams): Date => {
	const parsedDate = parseISODate(date);

	return subDays(parsedDate, amount);
};

const subDateWeeks = ({
	date,
	amount = 1,
}: DateDaysMethodsParams): Date => {
	const parsedDate = parseISODate(date);

	return subWeeks(parsedDate, amount);
};

const addDateMonths = ({
	date,
	amount = 1,
}: DateDaysMethodsParams): Date => {
	const parsedDate = parseISODate(date);

	return addMonths(parsedDate, amount);
};

const subDateMonths = ({
	date,
	amount = 1,
}: DateDaysMethodsParams): Date => {
	const parsedDate = parseISODate(date);

	return subMonths(parsedDate, amount);
};

const addDateYears = ({
	date,
	amount = 1,
}: DateDaysMethodsParams): Date => {
	const parsedDate = parseISODate(date);

	return addYears(parsedDate, amount);
};

const subDateYears = ({
	date,
	amount = 1,
}: DateDaysMethodsParams): Date => {
	const parsedDate = parseISODate(date);

	return subYears(parsedDate, amount);
};

const isDateValid = (date: DateParam): boolean => {
	return isValid(parseISODate(date));
};

enum InclusivitySymbol {
	INCLUDE_START = "[",
	INCLUDE_END = "]",
	EXCLUDE_START = "(",
	EXCLUDE_END = ")",
}

type InclusivityCombination =
	| `${InclusivitySymbol.INCLUDE_START}${InclusivitySymbol.INCLUDE_END}`
	| `${InclusivitySymbol.EXCLUDE_START}${InclusivitySymbol.INCLUDE_END}`
	| `${InclusivitySymbol.INCLUDE_START}${InclusivitySymbol.EXCLUDE_END}`
	| `${InclusivitySymbol.EXCLUDE_START}${InclusivitySymbol.EXCLUDE_END}`;

interface IsBetweenParams {
	date: DateParam;
	from: DateParam;
	to: DateParam;
	inclusivity?: InclusivityCombination;
}

const isBetween = ({
	date,
	from,
	to,
	inclusivity = `${InclusivitySymbol.INCLUDE_START}${InclusivitySymbol.INCLUDE_END}`,
}: IsBetweenParams): boolean => {
	if (
		!includes(
			[
				`${InclusivitySymbol.INCLUDE_START}${InclusivitySymbol.INCLUDE_END}`,
				`${InclusivitySymbol.EXCLUDE_START}${InclusivitySymbol.EXCLUDE_END}`,
				`${InclusivitySymbol.INCLUDE_START}${InclusivitySymbol.EXCLUDE_END}`,
				`${InclusivitySymbol.EXCLUDE_START}${InclusivitySymbol.INCLUDE_END}`,
			],
			inclusivity,
		)
	) {
		throw new Error("Inclusivity parameter must be one of (), [], (], [)");
	}

	const parsedDate = parseISODate(date);
	const parsedFrom = parseISODate(from);
	const parsedTo = parseISODate(to);

	const isBeforeEqual = inclusivity.at(0) === InclusivitySymbol.INCLUDE_START;
	const isAfterEqual = inclusivity.at(1) === InclusivitySymbol.INCLUDE_END;

	return (
		(
			isBeforeEqual
				? (
					isEqual(parsedFrom, parsedDate)
					|| isBefore(parsedFrom, parsedDate)
				)
				: isBefore(parsedFrom, parsedDate)
		)
		&& (
			isAfterEqual
				? (
					isEqual(parsedTo, parsedDate)
					|| isAfter(parsedTo, parsedDate)
				)
				: isAfter(parsedTo, parsedDate)
		)
	);
};

interface SetHoursAndMinutesParams {
	hours: number;
	minutes: number;
}

const setHoursAndMinutes = (params: SetHoursAndMinutesParams): Date => {
	return setSeconds(setMinutes(setHours(new Date(), params.hours), params.minutes), 0);
};

const startDateOfDay = (date: DateParam): Date => {
	return startOfDay(parseISODate(date));
};

const endDateOfDay = (date: DateParam): Date => {
	return endOfDay(parseISODate(date));
};

const startDateOfISOWeek = (date: DateParam): Date => {
	return startOfISOWeek(parseISODate(date));
};

const endDateOfISOWeek = (date: DateParam): Date => {
	return endOfISOWeek(parseISODate(date));
};

const startDateOfMonth = (date: DateParam): Date => {
	return startOfMonth(parseISODate(date));
};

const endDateOfMonth = (date: DateParam): Date => {
	return endOfMonth(parseISODate(date));
};

/**
 * @deprecated Use `toDateString` instead.
 */
const formatDefaultDate = (date: DateParam): string => {
	return format(parseISODate(date), DEFAULT_DATE_FORMAT);
};

const formatLocaleSlashDate = (date: DateParam): string => {
	return format(parseISODate(date), LOCAL_SLASH_FORMAT);
};

const formatDateFullMonthDate = (date: DateParam): string => {
	return format(parseISODate(date), DATE_FULL_MONTH_FORMAT);
};

const formatJournalHeaderDate = (date: DateParam): string => {
	return format(parseISODate(date), JOURNAL_HEADER_DATE_FORMAT);
};

const formatLockDate = (date: DateParam): string => {
	return format(parseISODate(date), LOCK_DATE_FORMAT);
};

const formatChooseDate = (date: DateParam): string => {
	return format(parseISODate(date), CHOOSE_DATE_FORMAT);
};

const formatPickerInputDate = (date: DateParam): string => {
	return format(parseISODate(date), PICKER_INPUT_DATE_FORMAT);
};

const formatMarkupStatusDate = (date: DateParam): string => {
	return format(parseISODate(date), MARKUP_STATUS_DATE_FORMAT);
};

const formatDefaultTimeDate = (date: DateParam): string => {
	return format(parseISODate(date), DEFAULT_TIME_DATE_FORMAT);
};

interface CalcFromAndToParams {
	period: string;
	date: string | Date;
}

interface CalcFromAndToParamsResult {
	toDate: string;
	fromDate: string;
}

const calcFromAndTo = ({
	period,
	date,
}: CalcFromAndToParams): CalcFromAndToParamsResult => {
	switch (period) {
		case PERIODS.TWO_WEEKS: {
			return {
				toDate: formatDefaultDate(endDateOfISOWeek(date)),
				fromDate: formatDefaultDate(subDateWeeks({
					date: startDateOfISOWeek(date),
					amount: 1,
				})),
			};
		}

		case PERIODS.MONTH: {
			return {
				toDate: formatDefaultDate(endDateOfMonth(date)),
				fromDate: formatDefaultDate(startDateOfMonth(date)),
			};
		}

		case CUSTOM_PERIOD:
		case PERIODS.WEEK:
		default: {
			return {
				toDate: formatDefaultDate(endDateOfISOWeek(date)),
				fromDate: formatDefaultDate(startDateOfISOWeek(date)),
			};
		}
	}
};

interface ArithmeticFromAndToParams {
	operation: Operation;
	period: string;
	fromDate: string;
}

interface ArithmeticFromAndToResult {
	fromDate: string;
	toDate: string;
}

const arithmeticFromAndTo = ({
	operation,
	period,
	fromDate,
}: ArithmeticFromAndToParams): ArithmeticFromAndToResult => {
	switch (operation) {
		case Operation.ADD_MONTH: {
			return calcFromAndTo({
				period,
				date: addDateMonths({
					date: fromDate,
					amount: 1,
				}),
			});
		}

		case Operation.SUB_MONTH: {
			return calcFromAndTo({
				period,
				date: subDateMonths({
					date: fromDate,
					amount: 1,
				}),
			});
		}

		default: {
			return calcFromAndTo({
				period,
				date: parseISODate(fromDate),
			});
		}
	}
};

const getPeriodArray = (fromDate: string, toDate: string): Date[] => {
	const dates: Date[] = [];
	const periodDays = getDifferenceInDays({
		date: toDate,
		dateToCompare: fromDate,
	});

	if (
		!isEmpty(fromDate)
		&& !isEmpty(toDate)
	) {
		for (
			let index = 0;
			(
				index <= periodDays
				&& index < MAX_DAY_IN_MONTH
			);
			index++
		) {
			dates.push(
				addDateDays({
					date: fromDate,
					amount: index,
				}),
			);
		}
	}

	return dates;
};

export {
	InclusivitySymbol,
	parseISODate,
	isDateEqual,
	isDateBefore,
	isDateAfter,
	isEqualOrBefore,
	isEqualOrAfter,
	isDateSameDay,
	getDifferenceInMinutes,
	getDifferenceInDays,
	getDifferenceInMonths,
	getMinDate,
	getMaxDate,
	getISOWeekDay,
	getDayOfWeek,
	getDayOfMonth,
	getMonthNumber,
	getDateYear,
	setDateDayOfYear,
	setDateMonth,
	setDateYear,
	addDateDays,
	subDateDays,
	subDateWeeks,
	addDateMonths,
	subDateMonths,
	addDateYears,
	subDateYears,
	isDateValid,
	isBetween,
	setHoursAndMinutes,
	startDateOfDay,
	endDateOfDay,
	startDateOfISOWeek,
	endDateOfISOWeek,
	startDateOfMonth,
	endDateOfMonth,
	formatDefaultDate,
	formatDateFullMonthDate,
	formatJournalHeaderDate,
	formatLocaleSlashDate,
	formatLockDate,
	formatChooseDate,
	formatPickerInputDate,
	formatMarkupStatusDate,
	formatDefaultTimeDate,
	calcFromAndTo,
	arithmeticFromAndTo,
	getPeriodArray,
};
