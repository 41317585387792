/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	getEmployeeFiltersData,
} from "components/projects-billing/actionsUtils";
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	type FilterValues,
} from "types/filter";
import {
	getApiError,
} from "utils/api";

import {
	type GetProjectFiltersDataParams,
	type GetUserProjectsParams,
	getProject,
	getProjectFiltersData,
	getProjects,
	getUserProjects,
} from "./endpoints";
import {
	type Project,
	type ProjectId,
	type ProjectIds,
	type Projects,
	type ProjectsShort,
} from "./types";

const projectsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProject: build.query<
				Project,
				ProjectId
			>({
				queryFn: async (params) => {
					try {
						const project = await getProject(params);

						return {
							data: project,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjects: build.query<
				Projects,
				ProjectIds
			>({
				queryFn: async (params) => {
					try {
						const projects = await getProjects(params);

						return {
							data: projects,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getUserProjects: build.query<
				ProjectsShort,
				GetUserProjectsParams
			>({
				queryFn: async (params) => {
					try {
						const userProjects = await getUserProjects(params);

						return {
							data: userProjects,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getProjectFiltersData: build.query<
				Pick<
					FilterValues,
					| "employees"
					| "countries"
					| "engagementPackages"
				>,
				GetProjectFiltersDataParams
			>({
				queryFn: async (params) => {
					try {
						const projectFiltersData = await getProjectFiltersData(params);

						const filterOptions = getEmployeeFiltersData(projectFiltersData);

						return {
							data: filterOptions,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	projectsApi,
};
