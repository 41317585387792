/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
enum KPSRoleId {
	ACCOUNT_MANAGER = "accountManagerUid",
	DELIVERY_MANAGER = "deliveryManagerUid",
	DELIVERY_SUPERVISOR = "deliverySupervisorUid",
}

const KPS_ROLE: Record<KPSRoleId, string> = {
	[KPSRoleId.ACCOUNT_MANAGER]: "Account Manager",
	[KPSRoleId.DELIVERY_MANAGER]: "Delivery Manager",
	[KPSRoleId.DELIVERY_SUPERVISOR]: "Delivery Supervisor",
};

export {
	KPSRoleId,
	KPS_ROLE,
};
