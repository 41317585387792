/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	overtimeApi,
} from "models/overtime/api";
import {
	type OvertimeRequestForStatusChange,
} from "models/overtime/endpoints";
import {
	type OvertimeRequest,
	type OvertimeRequestId,
	type OvertimeRequests,
} from "models/overtime/types";
import {
	type Dispatch,
} from "store";

import {
	getOvertimeRequestStatusChangeErrorMessage,
} from "../get-overtime-request-status-change-error-message/get-overtime-request-status-change-error-message";

interface ApproveOvertimeRequestsParams {
	isNewPermissionSchemeEnabled: boolean;
	overtimeRequestsToApprove: OvertimeRequests;
	getOvertimeRequestById: (
		overtimeRequestId: OvertimeRequestId,
	) => OvertimeRequest | undefined;
	dispatch: Dispatch;
}

const approveOvertimeRequests = async ({
	isNewPermissionSchemeEnabled,
	overtimeRequestsToApprove,
	getOvertimeRequestById,
	dispatch,
}: ApproveOvertimeRequestsParams): Promise<void> => {
	try {
		const adjustedOvertimeRequestsToApprove = overtimeRequestsToApprove.map<
			OvertimeRequestForStatusChange
		>((overtimeRequest) => {
			const {
				overtimeId,
				overtimeVersion,
			} = overtimeRequest;

			return {
				id: overtimeId,
				overtimeVersion,
			};
		});

		const approveOvertimeRequestsRequest = (
			isNewPermissionSchemeEnabled
				? overtimeApi.endpoints.approveOvertimeRequestsV3.initiate
				: overtimeApi.endpoints.approveOvertimeRequests.initiate
		);

		await dispatch(
			approveOvertimeRequestsRequest({
				overtimeRequestsToApprove: adjustedOvertimeRequestsToApprove,
			}),
		)
			.unwrap();
	} catch (error) {
		const errorMessage = getOvertimeRequestStatusChangeErrorMessage({
			error,
			getErrorMessageForOvertimeRequestId: (notApprovedOvertimeRequestId) => {
				const notApprovedOvertimeRequest = getOvertimeRequestById(
					notApprovedOvertimeRequestId,
				);

				if (isUndefined(notApprovedOvertimeRequest)) {
					return undefined;
				}

				const {
					employeeName,
					date,
				} = notApprovedOvertimeRequest;

				return `The overtime request for ${employeeName} for ${date} can't be approved.`;
			},
		});

		await Promise.reject(new Error(errorMessage));
	}
};

export {
	approveOvertimeRequests,
};
