/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	HttpStatusCode,
} from "axios";
import includes from "lodash/includes";
import isUndefined from "lodash/isUndefined";

import {
	type ApiError,
} from "types/api";

type ErrorCode = string;

type ErrorCodes = ErrorCode[];

interface ErrorData {
	message: string;
	codes?: ErrorCodes;
}

type LockUnlockServerError = ApiError<ErrorData>;

const getLockUnlockServerErrorMessage = (
	serverError: LockUnlockServerError,
): string => {
	const {
		response,
	} = serverError.data;

	if (!isUndefined(response)) {
		const {
			status: responseStatus,
		} = response;

		if (responseStatus === HttpStatusCode.Conflict) {
			const {
				data: serverResponse,
			} = response;

			if (includes(serverResponse.codes, "conflict.lock.action.period")) {
				return "Reporting lock cannot be applied to a period with billing lock.";
			}

			return serverResponse.message;
		}

		if (responseStatus === HttpStatusCode.Forbidden) {
			return "Selected action can't be performed";
		}
	}

	return "Something wrong";
};

export {
	getLockUnlockServerErrorMessage,
	type LockUnlockServerError,
};
