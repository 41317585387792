/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetProjectStatisticParams,
	getProjectStatistic,
} from "./endpoints";
import {
	type ProjectStatistic,
} from "./types";

const projectStatisticApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProjectStatistic: build.query<
				ProjectStatistic,
				GetProjectStatisticParams
			>({
				queryFn: async (params) => {
					try {
						const projectStatistic = await getProjectStatistic(params);

						return {
							data: projectStatistic,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				/*
					The state should contain the latest data (according to the requirements),
					so we don't need the data to be stored when all the subscribers are
					removed.
				*/
				keepUnusedDataFor: 0,
			}),
		};
	},
});

export {
	projectStatisticApi,
};
