/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetEmployeeReportPresenceMisreportingParams,
	type GetEmployeeReportSummaryParams,
	type GetEmployeeReportTotalForHrParams,
	type GetEmployeeReportTotalParams,
	type ReportResponse,
	getEmployeeReportPresenceMisreporting,
	getEmployeeReportSummary,
	getEmployeeReportTotal,
	getEmployeeReportTotalForHr,
} from "./endpoints";

const reportsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getEmployeeReportTotal: build.query<
				ReportResponse,
				GetEmployeeReportTotalParams
			>({
				queryFn: async (params) => {
					try {
						const reportResponse = await getEmployeeReportTotal(params);

						return {
							data: reportResponse,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getEmployeeReportTotalForHr: build.query<
				ReportResponse,
				GetEmployeeReportTotalForHrParams
			>({
				queryFn: async (params) => {
					try {
						const reportResponse = await getEmployeeReportTotalForHr(params);

						return {
							data: reportResponse,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getEmployeeReportSummary: build.query<
				ReportResponse,
				GetEmployeeReportSummaryParams
			>({
				queryFn: async (params) => {
					try {
						const reportResponse = await getEmployeeReportSummary(params);

						return {
							data: reportResponse,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
			getEmployeeReportPresenceMisreporting: build.query<
				ReportResponse,
				GetEmployeeReportPresenceMisreportingParams
			>({
				queryFn: async (params) => {
					try {
						const reportResponse = await getEmployeeReportPresenceMisreporting(params);

						return {
							data: reportResponse,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
				keepUnusedDataFor: 0,
			}),
		};
	},
});

export {
	reportsApi,
};
