/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeIds,
} from "models/employees/types";
import {
	type ArrayResponse,
} from "types/api";

import {
	type EmployeeLeaves,
} from "./types";

interface GetEmployeesLeavesParams extends WithPeriodDates {
	employeeIds: EmployeeIds;
}

type LeavesResponse = ArrayResponse<EmployeeLeaves>;

const getEmployeesLeaves = async ({
	employeeIds,
	periodStartDate,
	periodEndDate,
}: GetEmployeesLeavesParams): Promise<EmployeeLeaves> => {
	const {
		data: {
			values: employeesLeaves,
		},
	} = await api.get<LeavesResponse>(
		"leaves/days",
		{
			params: {
				employeeUids: employeeIds,
				startDate: periodStartDate,
				endDate: periodEndDate,
				statuses: [
					// Taken.
					"ACTUAL",
					// Requested.
					"PLANNED",
				],
			},
		},
	);

	return employeesLeaves;
};

export {
	getEmployeesLeaves,

	type GetEmployeesLeavesParams,
	type LeavesResponse,
};
