/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type ProjectId,
} from "models/projects/types";

import {
	type ProjectPermissionsInfo,
	type ProjectsPermissionsInfo,
	type UserPermissionsInfo,
} from "./types";

const getUserPermissionsInfo = async (): Promise<
	UserPermissionsInfo
> => {
	const {
		data: userPermissionsInfo,
	} = await api.get<UserPermissionsInfo>(
		"permission",
	);

	return userPermissionsInfo;
};

const getProjectPermissionsInfo = async (
	projectId: ProjectId,
): Promise<ProjectPermissionsInfo> => {
	const {
		data: projectPermissionsInfo,
	} = await api.get<ProjectPermissionsInfo>(
		`permission/project/${projectId}`,
	);

	return projectPermissionsInfo;
};

const getProjectsPermissionsInfo = async (): Promise<
	ProjectsPermissionsInfo
> => {
	const {
		data: projectsPermissionsInfo,
	} = await api.get<ProjectsPermissionsInfo>(
		"permission/cpe/project",
	);

	return projectsPermissionsInfo;
};

export {
	getUserPermissionsInfo,
	getProjectPermissionsInfo,
	getProjectsPermissionsInfo,
};
