/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

import {
	ActivityType,
	BillableType,
	WorklogStatus,
	WorklogType,
} from "models/comparisons/constants";
import {
	OvertimeStatus,
} from "models/overtime/constants";
import {
	type ProjectWorklogs,
} from "models/project-worklogs/types";

import {
	type ProjectPositionWorklogs,
} from "../types";

interface ConvertProjectWorklogsParams {
	worklogs: ProjectWorklogs;
}

const convertProjectWorklogs = ({
	worklogs,
}: ConvertProjectWorklogsParams): ProjectPositionWorklogs => {
	return worklogs.map((worklog) => {
		const {
			id,
			positionId,
			date,
			duration,
			isOvertime,
			isExcludedHours,
			isBillable,
			overtimeStatus,
			projectId,
		} = worklog;

		return {
			id,
			positionId,
			projectId,
			date,
			duration,
			activityType: ActivityType.PROJECT,
			worklogType: isExcludedHours
				? WorklogType.ON_DUTY
				: isOvertime
					? WorklogType.OVERTIME
					: WorklogType.WORK,
			billableType: isUndefined(isBillable)
				? undefined
				: isBillable
					? BillableType.BILLABLE
					: BillableType.NON_BILLABLE,
			status: isUndefined(overtimeStatus)
				? undefined
				: overtimeStatus === OvertimeStatus.APPROVED
					? WorklogStatus.APPROVED
					: WorklogStatus.SUBMITTED,
		};
	});
};

export {
	convertProjectWorklogs,
};
