/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type DateString,
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	type EmployeeId,
} from "models/employees/types";

import {
	type Project,
	type ProjectFiltersData,
	type ProjectId,
	type ProjectIds,
	type Projects,
	type ProjectsShort,
} from "./types";

const getProject = async (projectId: ProjectId): Promise<Project> => {
	const {
		data: project,
	} = await api.get<Project>(`project/${projectId}`);

	return project;
};

const getProjects = async (projectIds: ProjectIds): Promise<Projects> => {
	const {
		data: projects,
	} = await api.get<Projects>(
		"project/search",
		{
			params: {
				projectIds,
			},
		},
	);

	return projects;
};

interface GetUserProjectsParams {
	employeeId: EmployeeId;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

interface GetUserProjectsResponse {
	projects: ProjectsShort;
}

const getUserProjects = async ({
	employeeId,
	periodStartDate,
	periodEndDate,
}: GetUserProjectsParams): Promise<ProjectsShort> => {
	const {
		data: {
			projects: userProjects,
		},
	} = await api.get<GetUserProjectsResponse>(
		"project/accessible",
		{
			params: {
				employeeId,
				from: periodStartDate,
				to: periodEndDate,
			},
		},
	);

	return userProjects;
};

interface GetProjectFiltersDataParams extends WithPeriodDates {
	projectId: ProjectId;
}

const getProjectFiltersData = async ({
	projectId,
	periodStartDate,
	periodEndDate,
}: GetProjectFiltersDataParams): Promise<ProjectFiltersData> => {
	const {
		data: projectFiltersData,
	} = await api.get<ProjectFiltersData>(
		"dashboard/v2/filters",
		{
			params: {
				projectUid: projectId,
				from: periodStartDate,
				to: periodEndDate,
			},
		},
	);

	return projectFiltersData;
};

export {
	getProject,
	getProjects,
	getUserProjects,
	getProjectFiltersData,

	type GetUserProjectsParams,
	type GetUserProjectsResponse,
	type GetProjectFiltersDataParams,
};
