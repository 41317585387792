/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	reduxToolkitQueryApi,
} from "constants/api";
import {
	getApiError,
} from "utils/api";

import {
	type GetEmployeesProjectPositionsParams,
	type GetProjectPositionsParams,
	getEmployeesProjectPositions,
	getProjectPositions,
} from "./endpoints";
import {
	type PaginatedProjectPositions,
	type ProjectPositions,
} from "./types";

const projectPositionsApi = reduxToolkitQueryApi.injectEndpoints({
	endpoints: (build) => {
		return {
			getProjectPositions: build.query<
				PaginatedProjectPositions,
				GetProjectPositionsParams
			>({
				queryFn: async (params) => {
					try {
						const projectPositions = await getProjectPositions(params);

						return {
							data: projectPositions,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
			getEmployeesProjectPositions: build.query<
				ProjectPositions,
				GetEmployeesProjectPositionsParams
			>({
				queryFn: async (params) => {
					try {
						const employeeProjectPositions = await getEmployeesProjectPositions(params);

						return {
							data: employeeProjectPositions,
						};
					} catch (error) {
						return {
							error: getApiError(error),
						};
					}
				},
			}),
		};
	},
});

export {
	projectPositionsApi,
};
