/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type EmployeeTipTypes,
} from "./types";

enum PROJECT_TIP {
	OVERTIME_TO_VERIFY = "OVERTIME_TO_VERIFY",
	LEAVE_TO_VERIFY = "LEAVE_TO_VERIFY",
	NO_WORKLOAD = "NO_WORKLOAD",
	MISREPORTING_BILLABLE_POSITION = "MISREPORTING_BILLABLE_POSITION",
	POSITION_END_DATE_AND_TIME_GAP = "POSITION_END_DATE_AND_TIME_GAP",
	NO_PACKAGE_ASSIGNMENT = "NO_PACKAGE_ASSIGNMENT",
	WORKLOAD_MORE_THAN_NORM = "WORKLOAD_MORE_THAN_NORM",
	BILLING_UNLOCKED = "BILLING_UNLOCKED",
	NPA_AND_TIME_GAP = "NPA_AND_TIME_GAP",
	PRE_PRODUCTION_CATEGORY = "PRE_PRODUCTION_CATEGORY",
	EMPLOYEE_EXIT_DATE_AND_TIME_GAP = "EMPLOYEE_EXIT_DATE_AND_TIME_GAP",
	POSITION_TIME_OVERREPORTING = "POSITION_TIME_OVERREPORTING",
	POSITION_TIME_GAP = "POSITION_TIME_GAP",
	TIME_REPORTING_NO = "TIME_REPORTING_NO",
}
const PROJECT_TIP_TYPES = Object.values(PROJECT_TIP);

enum EmployeeTipType {
	LEAVE_TO_VERIFY = "LEAVE_TO_VERIFY",
	WORKLOAD_MORE_THAN_NORM = "WORKLOAD_MORE_THAN_NORM",
	OVERTIME_TO_VERIFY = "OVERTIME_TO_VERIFY",
	NPA_OVERTIME_TO_VERIFY = "NPA_OVERTIME_TO_VERIFY",
	REPORTING_GAP = "REPORTING_GAP",
	PRESENCE_GAP = "PRESENCE_GAP",
	PRESENCE_MAX_WORKING_HOURS_PER_DAY = "PRESENCE_MAX_WORKING_HOURS_PER_DAY",
	PRESENCE_MAX_WORKING_HOURS_PER_WEEK = "PRESENCE_MAX_WORKING_HOURS_PER_WEEK",
	PRESENCE_MAX_WORKING_HOURS_PER_MONTH = "PRESENCE_MAX_WORKING_HOURS_PER_MONTH",
	PRESENCE_MAX_WORKING_HOURS_PER_YEAR = "PRESENCE_MAX_WORKING_HOURS_PER_YEAR",
	PRESENCE_MAX_WORKING_DAY_DURATION = "PRESENCE_MAX_WORKING_DAY_DURATION",
	PRESENCE_RECOMMENDED_WORKING_PERIOD = "PRESENCE_RECOMMENDED_WORKING_PERIOD",
	PRESENCE_RESTRICTED_WORKING_PERIOD = "PRESENCE_RESTRICTED_WORKING_PERIOD",
	PRESENCE_MIN_BREAK_DURATION = "PRESENCE_MIN_BREAK_DURATION",
	PRESENCE_MIN_REST_DURATION = "PRESENCE_MIN_REST_DURATION",
	PRESENCE_DISCREPANCY = "PRESENCE_DISCREPANCY",
	PRESENCE_TO_VERIFY = "PRESENCE_TO_VERIFY",
}

const EMPLOYEE_PRESENCE_TIP_TYPES: EmployeeTipTypes = [
	EmployeeTipType.PRESENCE_GAP,
	EmployeeTipType.PRESENCE_MAX_WORKING_HOURS_PER_DAY,
	EmployeeTipType.PRESENCE_MAX_WORKING_HOURS_PER_WEEK,
	EmployeeTipType.PRESENCE_MAX_WORKING_HOURS_PER_MONTH,
	EmployeeTipType.PRESENCE_MAX_WORKING_HOURS_PER_YEAR,
	EmployeeTipType.PRESENCE_MAX_WORKING_DAY_DURATION,
	EmployeeTipType.PRESENCE_RECOMMENDED_WORKING_PERIOD,
	EmployeeTipType.PRESENCE_RESTRICTED_WORKING_PERIOD,
	EmployeeTipType.PRESENCE_MIN_BREAK_DURATION,
	EmployeeTipType.PRESENCE_MIN_REST_DURATION,
	EmployeeTipType.PRESENCE_DISCREPANCY,
	EmployeeTipType.PRESENCE_TO_VERIFY,
];

const EMPLOYEE_TIP_TYPES = Object.values(EmployeeTipType);

enum PROJECT_TIP_LEVEL {
	EMPLOYEE = "EMPLOYEE",
	POSITION = "POSITION",
}

enum CallToActionTipLevel {
	EMPLOYEE = "EMPLOYEE",
	PROJECT = "PROJECT",
}

export {
	PROJECT_TIP,
	PROJECT_TIP_TYPES,
	EmployeeTipType,
	EMPLOYEE_TIP_TYPES,
	EMPLOYEE_PRESENCE_TIP_TYPES,
	PROJECT_TIP_LEVEL,
	CallToActionTipLevel,
};
