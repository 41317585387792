/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	format,
} from "date-fns";

import {
	DEFAULT_DATE_FORMAT,
} from "../constants";
import {
	type DateString,
} from "../types";

/**
 * Turns a date into a date string in format "1970-01-01".
 */
const toDateString = (date: Date): DateString => {
	return format(date, DEFAULT_DATE_FORMAT);
};

export {
	toDateString,
};
