/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	api,
} from "constants/api";
import {
	type EmployeeId,
} from "models/employees/types";

import {
	type OutlookSyncInfo,
} from "./types";

const getOutlookSyncInfo = async (employeeId: EmployeeId): Promise<
	OutlookSyncInfo
> => {
	const {
		data: outlookSyncInfo,
	} = await api.get<OutlookSyncInfo>(`outlooksync/v1/users/${employeeId}/settings`);

	return outlookSyncInfo;
};

type UpdateOutlookSyncTypeRequestBody = Partial<OutlookSyncInfo>;

interface UpdateOutlookSyncTypeParams extends UpdateOutlookSyncTypeRequestBody {
	employeeId: EmployeeId;
}

const updateOutlookSyncType = async ({
	employeeId,
	...requestBody
}: UpdateOutlookSyncTypeParams): Promise<void> => {
	await api.patch(
		`outlooksync/v1/users/${employeeId}/settings`,
		requestBody,
	);
};

export {
	getOutlookSyncInfo,
	updateOutlookSyncType,

	type UpdateOutlookSyncTypeRequestBody,
	type UpdateOutlookSyncTypeParams,
};
