/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	format,
	parseISO,
} from "date-fns";
import isString from "lodash/isString";

import {
	FANCY_DATE_FORMAT,
} from "../constants";
import {
	type DateString,
} from "../types";

/**
 * Turns a date into a date string in format "January 1, 1970".
 */
const toFancyDate = (date: Date | DateString): string => {
	const dateToFormat = isString(date)
		? parseISO(date)
		: date;

	return format(dateToFormat, FANCY_DATE_FORMAT);
};

export {
	toFancyDate,
};
